<template>
  <div class="w-sm-100">
    <div class="row m0 hidden-xs">
      <div class="col-xs-12 bg-cl-tertiary summary-title">
        <h3 class="mobile-label-header py15 px0 m0 cl-white weight-400">
          {{ $t('Order Summary') }}
        </h3>
      </div>
    </div>
    <div class="pb10 px-lg-40 bg-cl-primary summary-content">
      <!-- <h3 class="cl-accent ml30 mt50 summary-title">{{ $t('Order Summary') }}</h3> -->
      <div class="checkout pt30 pb10  cl-accent brdr-bottom-1 brdr-cl-primary hidden-xs">
        <div class="row p-sm-8 total-labels">
          <div class="col-sm-8">
            {{ $t("Products") }}
          </div>
          <div class="col-sm-1 center-sm">
            {{ $t('Quantity') }}
          </div>
          <div class="col-sm-3 center-sm">
            {{ $t('Price') }}
          </div>
        </div>
      </div>
      <product v-for="(product, prodIndex) in productsInCart" :key="product.sku + prodIndex" :product="product" />
      <div class="checkout py10  cl-accent brdr-top-1 brdr-cl-primary">
        <div class="row p-sm-8 total-labels">
          <div class="col-xs  cl-secondary ff-semi">
            {{ $t('Delivery') }}:
          </div>
          <div class="col-xs align-right cl-accent h5 ff-light">
            <template v-if="this.$store.state.checkout.shippingDetails.firstName">
              {{ $t(shippingMethod()) }}
            </template>
            <template v-else>
              <span class="cl-matterhorn">{{ $t('Nie wybrano') }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="checkout py10  cl-accent brdr-top-1 brdr-cl-primary">
        <div class="row p-sm-8 total-labels">
          <div class="col-xs  cl-secondary ff-semi">
            {{ $t('Payment method') }}:
          </div>
          <div class="col-xs align-right cl-accent h5 ff-light">
            <template v-if="this.$store.state.checkout.paymentDetails.firstName">
              {{ $t(paymentMethod()) }}
            </template>
            <template v-else>
              <span class="cl-matterhorn">{{ $t('Nie wybrano') }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="checkout py10  cl-accent brdr-top-1 brdr-cl-primary">
        <!-- 'flatratefive': hiding delivery data if self receive selected -->
        <div class="row p-sm-8 total-labels" v-if="shippingDetails.shippingCarrier !== 'flatratefive'">
          <div class="col-xs-4 cl-secondary ff-semi">
            {{ $t('Delivery details') }}:
          </div>
          <div v-if="shippingDetails.streetAddress" class="col-xs align-right cl-accent h5">
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ shippingDetails.firstName }} {{ shippingDetails.lastName }}
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0" v-if="shippingDetails.company && shippingDetails.company.length">
              {{ shippingDetails.company }}
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ shippingDetails.streetAddress }} {{ shippingDetails.apartmentNumber }}{{ shippingDetails.flatNumber && ` / ${shippingDetails.flatNumber}` }},
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ shippingDetails.zipCode }}, {{ shippingDetails.city }}
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ shippingDetails.phoneNumber }}
            </p>
          </div>
          <div v-else class="col-xs align-right cl-accent h5">
            <p class="shipping-address-label ff-light m0 cl-matterhorn">
              {{ $t('Nie wybrano') }}
            </p>
          </div>
        </div>
        <div class="row p-sm-8 total-labels" v-if="paymentDetails.streetAddress">
          <div class="col-xs-4 cl-secondary ff-semi">
            {{ $t('Customer Details') }}:
          </div>
          <div class="col-xs align-right cl-accent h5">
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ paymentDetails.firstName }} {{ paymentDetails.lastName }}
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ paymentDetails.company }}
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ paymentDetails.streetAddress }} {{ paymentDetails.apartmentNumber }}{{ paymentDetails.flatNumber && ` / ${paymentDetails.flatNumber}` }},
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0">
              {{ paymentDetails.zipCode }}, {{ paymentDetails.city }}
            </p>
            <p class="shipping-address-label ff-light fs-medium-small m0" v-if="isNipSelected && customTaxId">
              NIP: {{ paymentDetails.customTaxId }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="productsInCart && productsInCart.length"
        class="checkout py10  cl-accent brdr-top-1 brdr-cl-primary"
      >
        <div
          v-for="(segment, index) in totals"
          :key="index"
          class="row p-sm-8 total-labels"
          v-if="segment.code !== 'grand_total'"
        >
          <div class="col-xs cl-secondary ff-semi" v-if="segment.code === 'subtotalInclTax'">
            {{ $t('Wartość zamówienia') }}:
          </div>
          <div class="col-xs cl-secondary ff-semi" v-if="segment.code === 'discount'">
            {{ $t('Discount code') }}:
          </div>
          <div class="col-xs cl-secondary ff-semi" v-if="segment.code === 'shipping'">
            {{ $t('Delivery') }}:
          </div>
          <div class="col-xs cl-secondary ff-semi" v-if="segment.code === 'tax'">
            VAT 23%:
          </div>
          <div
            v-if="segment.value != null"
            class="col-xs align-right cl-secondary h5 ff-light"
          >
            <template v-if="segment.code === 'shipping'">
              {{ Math.round(Math.abs(segment.value) * 1.23) | price }}
            </template>
            <template v-else>
              {{ Math.abs(segment.value) | price }}
            </template>
          </div>
        </div>
        <div
          class="row grannd-label cl-black p-sm-8"
          v-for="(segment, index) in totals"
          :key="index"
          v-if="segment.code === 'grand_total'"
        >
          <div class="col-xs ff-semi">
            <!--{{ segment.title }}-->{{ $t('Suma') }}:
          </div>
          <div class="col-xs align-right ff-semi">
            {{ segment.value | price }}
          </div>
        </div>
      </div>
      <div class="checkout pt10 cl-accent brdr-top-1 brdr-cl-primary hidden-xs">
        <p class="h5 my8">
          {{ $t('Delivery') }}
        </p>
        <p class="h6 weight-100 my8">
          {{ $t('We guarantee fast and convenient delivery by courier to the indicated address from 24 hours after placing the order.') }}
        </p>
        <p class="h5 my8">
          {{ $t('Returns') }}
        </p>
        <p class="h6 weight-100 my8">
          {{ $t('In case of any doubts we guarantee the possibility of returning the products received within 14 days from the date of purchase.') }}
        </p>
        <p class="h5 my8">
          {{ $t('History') }}
        </p>
        <p class="h6 weight-100 my8">
          {{ $t('Sklep Artykuły Grzewcze istnieje od 2010 roku. Od chwili powstania oferujemy szeroki wybór uznanych marek i najwyższą jakość usług. Dbamy, aby nasza oferta odzwierciedlała najlepsze trendy a sprzedawane u nas produkty były unikalne i wyjątkowe.') }}
        </p>
      </div>
    </div>
    <!-- <div class="py50 px25">
      <h4 class="h3 m0">{{ $t('Safety') }}</h4>
      <p class="cl-accent lh20">
        U nas kupujesz bezpiecznie!
        <a
          href="/bezpieczenstwo-transakcji"
          target="_blank"
        >Kliknij tutaj</a>
        {{ ' ' }}i zobacz jak dbamy o bezpieczeństwo naszych klientów.
      </p>
      <h4 class="h3 mb0">{{ $t('Shipping') }}</h4>
      <p class="cl-accent lh20">
        Zdecydowaną większość zamówień złożonych do południa w dniu roboczym wysyłamy tego samego dnia.
        <a
          href="/wysylka-i-dostawa"
          target="_blank"
        >Kliknij tutaj</a>
        {{ ' ' }}aby dowiedzieć się więcej o czasie i kosztach dostawy Twojej paczki.
      </p>
      <h4 class="h3 mb0">{{ $t('Returns') }}</h4>
      <p class="cl-accent lh20">
        Masz prawo do zrezygnowania z kupionego towaru na Bardotti.pl bez podawania przyczyny w ciągu 14 dni od odebrania przesyłki.
        <a href="/zwroty-i-reklamacje" target="_blank">Kliknij tutaj</a>
        {{ ' ' }}aby sprawdzić jak przebiega proces reklamacji i zwrotów.
      </p>
    </div> -->
  </div>
</template>

<script>
import { CartSummary } from '@vue-storefront/core/modules/checkout/components/CartSummary'
import { mapState } from 'vuex';
import rootStore from '@vue-storefront/core/store';
import Product from './Product'

export default {
  components: {
    Product
  },
  mixins: [CartSummary],
  computed: {
    ...mapState({
      shippingDetails: state => state.checkout.shippingDetails,
      paymentDetails: state => state.checkout.paymentDetails
    }),
    customTaxId () {
      return rootStore.state.customTaxId
    },
    isNipSelected () {
      return this.$store.state.ui.selectedCheckoutType === 'Firma'
    }
  },
  methods: {
    shippingMethod () {
      let shippingCode = this.shippingDetails.shippingCarrier
      let currentShippingMethod = this.$store.getters['shipping/shippingMethods'].find(item => item.carrier_code === shippingCode)
      if (currentShippingMethod && currentShippingMethod.method_title) {
        return currentShippingMethod.method_title
      } else {
        return null
      }
    },
    paymentMethod () {
      let id = this.paymentDetails.paymentMethod
      if (id === 'vue_payu_gateway_blik') {
        return 'Blik'
      } else if (id === 'vue_payu_gateway') {
        return 'PayU'
      } else if (id === 'vue_payu_gateway_pay_card') {
        return 'PayU Karta Płatnicza'
      } else if (id === 'vue_payu_gateway_pay_twisto') {
        return 'Twisto - Zamów teraz i zapłać w ciągu 30 dni'
      } else if (id === 'vue_payu_gateway_pay_raty') {
        return 'PayU - Kup na raty'
      } else if (id === 'vue_payu_gateway_applepay') {
        return 'ApplePay'
      } else if (id === 'cashondelivery') {
        return 'Za pobraniem'
      } else if (id === 'pay_in_store') {
        return 'Płatność kartą lub gotówką w salonie'
      } else {
        return id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-title {
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.grannd-label {
  font-size: 20px;
  font-weight: bold;
}
 @media (max-width: 767px) {
  .mobile-footer-nav-but {
    background-color: #1166fb;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .p-sm-8 {
    padding: 2px 8px;
  }
  .shipping-address-label {
    margin: 0;
    font-size: 16px;
  }
 }
 @media (min-width: 768px) {
  .summary-content {
    border: 2px solid #c6c6c6;
  }
  .bg-cl-secondary-lg {
    background-color: #f2f2f2;
  }
  .px-lg-20 {
    padding-left: 20px;
    padding-right:20px;
  }
  .px-lg-40 {
    padding-left: 40px;
    padding-right:40px;
  }
  .w-sm-100 {
    width: 100%
  }
  .total-labels {
    font-size: 18px;
    padding: 3px 0px;
  }
  .grannd-label {
    font-size: 22px;
    padding: 5px 0;
  }
  .summary-title {
    text-transform: uppercase;
    text-align: center
  }
 }
</style>

<template>
  <div>
    <div class="row pl20 hidden-xs step-title">
      <div class="col-xs-1 col-sm-2 col-md-1 p0">
        <div
          class="number-circle lh35 brdr-1 brdr-cl-tertiary brdr-circle align-center weight-700 bg-cl-white cl-tertiary"
          :class="{
            'bg-cl-alternative brdr-cl-alternative active' : isActive,
            'bg-cl-tertiary cl-tertiary' : !isFilled && !isActive,
            'bg-cl-tertiary cl-tertiary' : isFilled && !isActive
          }"
        />
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row">
          <div
            class="col-xs-12 col-md-12 between-sm flex middle-sm pointer"
            @click.prevent="edit"
          >
            <p class="m0 h4 lh30 uppercase weight-700">
              {{ $t('Shipping') }}
            </p>
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-accent h5 flex edit-shipping" @click.prevent="edit">
                <span class="pr5">{{ $t('Edit shipping') }}</span>
                <img :src="'/assets/svg/create.svg'" class="filter-gray" width="26px" height="26px" alt="edit">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-2" />
      <div class="col-xs-12 col-sm-9 col-md-10 p-0">
        <div class="row">
          <p class="col-xs-12 h6 mobile-label-header">
            {{ $t('Shipping method') }}
          </p>
          <div
            v-for="(method, index) in shippingMethods"
            :key="index"
            class="col-md-12 p-0-20-xs flex relative middle-xs"
          >
            <label class="radioStyled cl-black radio-shipping">
              {{ method.method_title }} <br> {{ method.amount | price }}
              <input
                type="radio"
                :value="method.carrier_code"
                name="shipping-method"
                v-model="shipping.shippingCarrier"
                @change="
                  $v.shipping.shippingMethod.$touch();
                  changeShippingMethodCustom(method);
                "
              >
              <span class="checkmark" />
            </label>
            <img v-if="method.method_title === 'Poczta Polska - Paczka48'" src="/assets/poczta.png" alt="Poczta polska logo" height="30px" width="auto" class="ml10">
            <img v-if="method.method_title === 'Paczka w RUCHu'" src="/assets/Paczka w RUCHu.png" alt="Ruch logo" height="30px" width="auto" class="ml10">
            <img v-if="method.method_title === 'Paczkomaty InPost'" src="/assets/inpost_paczkomaty.png" alt="inPost logo" height="35px" width="auto" class="ml10">
            <img v-if="method.method_title === 'Kurier DPD'" src="/assets/dpdLogo.png" alt="dpd logo" height="30px" width="auto" class="ml10">
          </div>
          <span
            class="col-xs-12 h6 cl-error align-center"
            v-if="shipping.shippingCarrier === 'flatratethree'"
          >
            {{ $t("Czas realizacji wybranej dostawy wynosi do 7 dni roboczych") }}
          </span>
          <span
            class="validation-error"
            v-if="
              $v.shipping.shippingMethod.$error &&
                !$v.shipping.shippingMethod.required
            "
          >{{ $t('Field is required') }}</span>

          <p class="col-xs-12 mb25 h6 mobile-label-header" v-show="!isFlaterateOne">
            {{ $t('My shipping details') }}
          </p>
          <div v-show="isOpen.inputs" class="row">
            <base-checkbox
              v-if="currentUser && hasShippingDetails()"
              class="col-xs-12 mb25 shipToMyAddressCheckbox"
              id="shipToMyAddressCheckbox"
              v-model="shipToMyAddress"
            >
              {{ $t('Ship to my default address') }}
            </base-checkbox>

            <base-input
              v-if="!isPickupMethod"
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="first-name"
              :placeholder="$t('First name *')"
              v-model.trim="shipping.firstName"
              @blur="$v.shipping.firstName.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition:
                    $v.shipping.firstName.$error &&
                    !$v.shipping.firstName.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.firstName.minLength,
                  text: $t('Name must have at least 3 letters.')
                },
                {
                  condition: !$v.shipping.firstName.stringRegex && $v.shipping.firstName.minLength,
                  text: $t('First name can only contain letters.')
                }
              ]"
            />

            <base-input
              v-if="!isPickupMethod"
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="last-name"
              :placeholder="$t('Last name *')"
              v-model.trim="shipping.lastName"
              @blur="$v.shipping.lastName.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition:
                    $v.shipping.lastName.$error &&
                    !$v.shipping.lastName.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.lastName.stringRegex,
                  text: $t('Surname can only contain letters.')
                }
              ]"
            />

            <base-input
              v-if="!isPickupMethod"
              class="col-xs-12 col-sm-12 mb25"
              type="text"
              name="company-name"
              :placeholder="$t('Company name')"
              v-model.trim="shipping.company"
              autocomplete="fakeautocomplete"
            />

            <base-input
              class="col-xs-6 mb25"
              type="text"
              name="street-address"
              :placeholder="$t('Street name *')"
              v-model.trim="shipping.streetAddress"
              @blur="$v.shipping.streetAddress.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition:
                    $v.shipping.streetAddress.$error &&
                    !$v.shipping.streetAddress.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.streetAddress.maxLength,
                  text: $t('Value is too long')
                }
              ]"
            />

            <base-input
              class="col-xs-6 mb25"
              type="text"
              name="apartment-number"
              :placeholder="$t('House/Apartment number *')"
              v-model.trim="shipping.apartmentNumber"
              @blur="$v.shipping.apartmentNumber.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition:
                    $v.shipping.apartmentNumber.$error &&
                    !$v.shipping.apartmentNumber.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.apartmentNumber.maxLength,
                  text: $t('Value is too long')
                }
              ]"
            />

            <base-input
              class="col-xs-6 mb25"
              type="text"
              name="flat-number"
              :placeholder="$t('Flat number')"
              v-model.trim="shipping.flatNumber"
              autocomplete="fakeautocomplete"
            />

            <base-input
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="city"
              :placeholder="$t('City *')"
              v-model.trim="shipping.city"
              @blur="$v.shipping.city.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition:
                    $v.shipping.city.$error && !$v.shipping.city.required,
                  text: $t('Field is required')
                },
                {
                  condition: $v.shipping.city.$error && $v.shipping.city.required,
                  text: $t('Please provide valid city name')
                },
                {
                  condition: !$v.shipping.city.maxLength,
                  text: $t('Value is too long')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="zip-code"
              :placeholder="$t('Zip-code *')"
              v-model.trim="shipping.zipCode"
              @blur="$v.shipping.zipCode.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition:
                    $v.shipping.zipCode.$error && !$v.shipping.zipCode.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.zipCode.minLength,
                  text: $t('Zip-code must contain at least 6 characters.')
                },
                {
                  condition:
                    $v.shipping.zipCode.minLength &&
                    !$v.shipping.zipCode.postCodeRegex,
                  text:$t('Enter the zip code in the xx-xxx format.')
                },
                {
                  condition: !$v.shipping.zipCode.maxLength,
                  text: $t('Value is too long')
                }
              ]"
            />

            <base-input
              class="col-xs-12 col-md-6 mb25 fs-sm-14"
              type="text"
              name="phone-number"
              :placeholder="$t('Phone Number')"
              @blur="$v.shipping.phoneNumber.$touch()"
              v-model.trim="shipping.phoneNumber"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition: $v.shipping.phoneNumber.$error && !$v.shipping.phoneNumber.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.phoneNumber.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <div
              id="close-variable-butt"
              class="col-xs-12 close-variable-butt"
              @click="toggleDiv"
            >
              {{ $t("Finish editing") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20" v-show="isActive && isOpen.labels && !isFlaterateOne">
      <div class="hidden-xs col-sm-2" />
      <div class="col-xs-12 col-sm-9 p-0">
        <div class="row fs16 mb35 p-0-20-xs m-0-lg">
          <div
            class="col-xs-12 h4 p-0 cl-black h5"
            data-testid="shippingAddressSummary"
            v-if="isPickupMethod"
          >
            <div v-if="shipping.pickupId">
              <p class="m0">
                <b>{{ shipping.pickupId }}</b>
              </p>
              <p class="m0">
                {{ shipping.streetAddress }} {{ shipping.apartmentNumber }}
                {{ shipping.flatNumber ? "/ " + shipping.flatNumber : "" }}
              </p>
              <p class="m0">
                {{ shipping.city }} {{ shipping.zipCode }}
              </p>
              <p class="m0">
                <span v-if="shipping.state">{{ shipping.state }},</span>
                <span>{{ getCountryName() }}</span>
              </p>
            </div>
            <div v-else>
              <b>{{ $t('You have not selected a delivery point on the map.') }}
                <button class="open-pickup-points" @click="openPickupPoints">
                  {{ $t('Choose delivery point') }}
                </button></b>
            </div>
            <p v-if="shipping.phoneNumber" class="m0">
              <span class="pr15">{{ shipping.phoneNumber }}</span>
            </p>
            <div
              id="open-variable-butt"
              class="open-variable-butt cl-secondary h6 mt20 relative"
              @click="toggleDiv"
            >
              {{ $t("The phone number was copied from the customer's details.") }}
              <span class="cl-error">{{ $t("Change") }}</span>
              <i class="icon-edycja_black h4 edit-icon" />
            </div>
          </div>
          <div
            class="col-xs-12 h4 p-0 cl-black h5"
            data-testid="shippingAddressSummary"
            v-else
          >
            <p class="m0">
              {{ shipping.firstName }} {{ shipping.lastName }}
            </p>
            <p class="m0">
              {{ shipping.company }}
            </p>
            <p class="m0">
              {{ shipping.streetAddress }} {{ shipping.apartmentNumber }}
              {{ shipping.flatNumber ? '/ ' + shipping.flatNumber : '' }}
            </p>
            <p class="m0">
              {{ shipping.city }} {{ shipping.zipCode }}
            </p>
            <p class="m0">
              <span v-if="shipping.state">{{ shipping.state }},</span>
              <span>{{ getCountryName() }}</span>
            </p>
            <p v-if="shipping.phoneNumber" class="m0">
              <span class="pr15">{{ shipping.phoneNumber }}</span>
            </p>
            <div
              id="open-variable-butt"
              class="open-variable-butt cl-secondary h6 mt20 relative"
              @click="toggleDiv"
            >
              {{ $t("The address was copied from the customer's details.") }}
              <span class="cl-orange pointer">{{ $t("Change") }}</span>
              <i class="icon-edycja_black h4 edit-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20" v-if="isActive">
      <div class="hidden-xs col-sm-2" />
      <!-- <div class="col-xs-12 col-sm-10 p-0">
        <template v-if="!$v.shipping.country.mustBeCool">
          <span class="error-message h6">
            {{ $t('Shipping only available to Poland. Please change delivery country.') }}
          </span>
        </template>
      </div> -->
    </div>
    <div class="row mobile-footer-nav" v-if="isActive">
      <div class="hidden-xs col-sm-2" />
      <div class="col-xs-12 col-sm-9 save-bottom" @click="changeFilled">
        <div class="row">
          <div class="col-xs-12 col-md-8 my20 m-xs-0">
            <button-full
              data-testid="shippingSubmit"
              @click.native="sendDataToCheckout"
              :disabled="$v.shipping.$invalid || (isOpen.inputs || (isPickupMethod && !shipping.pickupId)) || (generateInvoice && !isNipDataResponse)"
              class="mobile-footer-nav-but"
            >
              {{ $t('Continue to payment') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
    <pickup-points :shipping="shipping" class="pickup-points" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { Shipping } from '@vue-storefront/core/modules/checkout/components/Shipping';

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox';
import BaseInput from 'theme/components/core/blocks/Form/BaseInput';
import ButtonFull from 'theme/components/theme/ButtonFull';
import PickupPoints from 'theme/components/core/blocks/Checkout/PickupPoints.vue';
import rootStore from '@vue-storefront/core/store';

// const mustBeCool = (value) => value.indexOf('PL') >= 0

const stringRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  // special characters validation
  return (/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).test(value)
};

const postCodeRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /\d{2}-\d{3}/g.test(value);
};

export default {
  data () {
    return {
      isOpen: { labels: true, inputs: false },
      copiedAddress: null,
      isPickupMethod: false,
      generateInvoice: false,
      inPostWasOpened: false,
      isCOD: true,
      isNipDataResponse: false,
      isFlaterateOne: false
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput,
    PickupPoints
  },
  watch: {
    isActive () {
      if (this.isActive && !this.copiedAddress) {
        this.copiedAddress = { ...this.$store.state.checkout.shippingDetails };
      }
    }
  },
  mounted () {
    this.copiedAddress = { ...this.$store.state.checkout.shippingDetails };
    this.changeFilled();
    this.shipping.pickupId = null;
    window.addEventListener(
      'message',
      e => {
        if (e.origin === this.urlPickupPoints) {
          if (Object.keys(e.data).length !== 0) {
            window.globalDataWidget = e.data;
            window.globalOriginalWidget = e.origin;
            const result = window.globalDataWidget;
            this.isCOD = false
            this.paymentDetails.isCOD = false
            this.shipping.shippingMethod = result.pickup_type;
            this.shipping.pickupId = result.points.id;
            this.shipping.streetAddress = result.street;
            this.shipping.apartmentNumber = '';
            this.shipping.city = result.city;
            this.shipping.zipCode = result.zip;
            this.shipping.country = 'PL';
            this.inPostWasOpened = true;
            this.closePickupPoints();
          } else {
            this.closePickupPoints();
          }
        }
      },
      false
    );
  },
  destroyed () {
    window.removeEventListener('message', null);
  },
  methods: {
    notInMethods (method) {
      let availableMethods = this.shippingMethods
      if (availableMethods.find(item => item.method_code === method)) {
        return false
      } else if (method === 'In Post' || method === 'Paczka w RUCHu') {
        return false
      }
      return true
    },
    onAfterPersonalDetails (receivedData) {
      if (!this.isFilled) {
        this.$store.dispatch('checkout/updatePropValue', ['firstName', receivedData.firstName])
        this.$store.dispatch('checkout/updatePropValue', ['lastName', receivedData.lastName])
        this.$store.dispatch('checkout/updatePropValue', ['phoneNumber', receivedData.phoneNumber])
        if (this.getCurrentMethodCode() === rootStore.state.config.shipping.salonMethod) {
          this.isFlaterateOne = true
          this.$store.dispatch('checkout/updatePropValue', ['streetAddress', 'ul Bartycka 24'])
          this.$store.dispatch('checkout/updatePropValue', ['apartmentNumber', '26'])
          this.$store.dispatch('checkout/updatePropValue', ['city', 'Warszawa'])
          this.$store.dispatch('checkout/updatePropValue', ['zipCode', '00-716'])
          this.$store.dispatch('checkout/updatePropValue', ['country', 'PL'])
        } else {
          this.$store.dispatch('checkout/updatePropValue', ['streetAddress', receivedData.streetAddress])
          this.$store.dispatch('checkout/updatePropValue', ['apartmentNumber', receivedData.apartmentNumber])
          if (receivedData.flatNumber !== '') {
            this.$store.dispatch('checkout/updatePropValue', ['flatNumber', receivedData.flatNumber])
          }
          this.$store.dispatch('checkout/updatePropValue', ['city', receivedData.city])
          this.$store.dispatch('checkout/updatePropValue', ['zipCode', receivedData.zipCode])
          this.$store.dispatch('checkout/updatePropValue', ['country', 'PL'])
        }
      }
    },
    openPickupPoints () {
      this.$bus.$emit('modal-show', 'modal-pickup-points');
    },
    closePickupPoints () {
      this.$bus.$emit('modal-hide', 'modal-pickup-points');
      if (this.shipping.shippingMethod === 'Paczka w RUCHu' && this.shipping.shippingCarrier !== 'flatratethree') {
        this.changeShippingMethodByCode('flatratethree')
      } else if (this.shipping.shippingMethod === 'In Post' && this.shipping.shippingCarrier !== 'flatratetwo') {
        this.changeShippingMethodByCode('flatratetwo')
      }
    },
    getShippingMethodByCode (code) {
      let currentMethod = this.shippingMethods ? this.shippingMethods.find(item => item.carrier_code === code) : {}
      return currentMethod
    },
    changeShippingMethodByCode (code) {
      let currentShippingMethod = this.getShippingMethodByCode(code)
      if (currentShippingMethod) {
        this.shipping = Object.assign(this.shipping, { shippingCarrier: currentShippingMethod.carrier_code })
        this.$bus.$emit('checkout-after-shippingMethodChanged', {
          country: this.shipping.country,
          method_code: currentShippingMethod.method_code,
          carrier_code: currentShippingMethod.carrier_code,
          payment_method: this.paymentMethod[0].code
        })
      }
    },
    getCurrentMethodCode () {
      let shippingCode = this.$store.state.checkout.shippingDetails.shippingCarrier
      let currentShippingMethod = this.$store.getters['shipping/shippingMethods'].find(item => item.carrier_code === shippingCode)
      if (currentShippingMethod && currentShippingMethod.method_title) {
        return currentShippingMethod.carrier_code
      } else {
        return null
      }
    },
    getCurrentShippingMethod () {
      let shippingCode = this.shipping.shippingCarrier
      let currentMethod = this.shippingMethods ? this.shippingMethods.find(item => item.carrier_code === shippingCode) : {}
      return currentMethod
    },
    toggleDiv () {
      this.isOpen.inputs = this.isOpen.labels;
      this.isOpen.labels = !this.isOpen.labels;
    },
    changeFilled () {
      this.$emit('shippingUpdate', this.isFilled);
    },
    changeShippingMethodCustom (method) {
      // if (method.includes('Paczkomaty') || method.includes('RUCH')) {
      //   this.isPickupMethod = true;
      //   this.openPickupPoints();
      //   this.changeShippingMethod();
      // } else {
      this.paymentDetails.isCOD = true;
      this.isPickupMethod = false;
      this.shipping.pickupId = null;
      this.changeShippingMethod();
      if (method.carrier_code === rootStore.state.config.shipping.salonMethod) {
        this.isFlaterateOne = true
        this.shipping.streetAddress = 'ul Bartycka 24'
        this.shipping.apartmentNumber = '26'
        this.shipping.city = 'Warszawa'
        this.shipping.zipCode = '00-716'
        this.shipping.country = 'PL'
        this.shipping.flatNumber = ''
      } else {
        this.isFlaterateOne = false
        this.shipping.streetAddress = this.personalDetails.streetAddress
        this.shipping.apartmentNumber = this.personalDetails.apartmentNumber
        this.shipping.city = this.personalDetails.city
        this.shipping.zipCode = this.personalDetails.zipCode
        this.shipping.country = this.personalDetails.country
        if (this.personalDetails.flatNumber !== '') {
          this.shipping.flatNumber = this.personalDetails.flatNumber
        }
      }
    }
  },
  mixins: [Shipping],
  computed: {
    ...mapState({
      paymentDetails: state => state.checkout.paymentDetails,
      personalDetails: state => state.checkout.personalDetails
    }),
    urlPickupPoints () {
      return rootStore.state.config.apiKeys.urlPickupPoints
    },
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  validations: {
    shipping: {
      firstName: {
        required,
        minLength: minLength(3),
        stringRegex
      },
      lastName: {
        required,
        stringRegex
      },
      country: {
        required
      },
      streetAddress: {
        required,
        maxLength: maxLength(100)
      },
      apartmentNumber: {
        required,
        maxLength: maxLength(10)
      },
      shippingMethod: {
        required
      },
      zipCode: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
        postCodeRegex
      },
      city: {
        required,
        maxLength: maxLength(100)
      },
      phoneNumber: {
        required,
        maxLength: maxLength(22)
      },
      company: {
        minLength: minLength(2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-gray {
  filter: contrast(0.3);
}
.check {
  width: 16px;
  height: 16px;
}
.step-title {
  @media (min-width: 768px) {
    display: flex;
  }
  align-items: center;
  margin-bottom: 15px;
  padding-left: 27px;
}
.pl-sm-20 {
  padding-left: 20px;
}
@media (min-width: 768px) {
  .m-0-lg {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.disable-but {
  background-color: #d9d9d9;
}
@media (max-width: 767px) {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .pl-sm-20 {
    padding-left: 0px;
  }
  .mobile-footer-nav {
    margin: 0;
    position: fixed;
    background: white;
    width: 100%;
    padding: 10px 30px;
    font-size: 1rem;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid #cecece;
    z-index: 10;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mobile-footer-nav-but {
    width: 100%;
  }
  .disable-but {
    background-color: #d9d9d9;
  }
  .m-xs-0 {
    margin: 0;
  }
  .mobile-label-header {
    margin-top: 0;
    padding: 7px 0px 7px 16px;
    background-color: #f5f5f5;
    color: #4d4d4d;
    text-transform: uppercase;
  }
  .radioStyled {
    margin-bottom: 18px !important;
  }
  .label-text {
    color: #4d4d4d;
    font-size: 16px;
  }
  .edit-icon {
    position: absolute;
    bottom: 5px !important;
    color: #a5a5a5;
  }
  .close-variable-butt {
    font-size: 12px;
    font-weight: bold;
    color: #ff5100;
    text-align: right;
    width: 100%;
    padding-bottom: 10px;
  }
  .p-0 {
    padding: 0;
  }
  .p-0-20-xs {
    padding: 0 20px;
  }
}
.edit-icon {
  position: absolute;
  right: 0px;
  color: #a5a5a5;
  cursor: pointer;
}
.close-variable-butt {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #ff5100;
  text-align: right;
  width: 100%;
}
.p-0 {
  padding: 0;
}
#autosuggest {
  width: 100%;
}

#autosuggest {
  /deep/ #autosuggest__input {
    box-sizing: border-box;
    -webkit-appearance: none;
    // height: 38px;
    // line-height: 38px;
    background: inherit;
    border: none;
    border-bottom: 1px solid #d3d3d3;
    border-radius: 0px;
    padding: 10px 0;
    font-size: 20px;
    width: 100%;
    font-family: "protipo",sans-serif;
  }
  /deep/ #autosuggest__input:placeholder-shown  {
    font-size: 14px;
  }
  /deep/ #autosuggest__input:focus,
  /deep/ #autosuggest__input:hover {
    outline: none;
    border-color: #ff5100;
  }

  /deep/ .autosuggest__results-container {
    position: relative;
    width: 100%;
    background-color: var(--theme-bg);
  }
  /deep/ .autosuggest__results {
    background-color: #fff;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 0;
    overflow: scroll;
    max-height: 400px;
  }
  /deep/ .autosuggest__results li:hover {
    background: #eee;
  }
  /deep/ .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  /deep/ .autosuggest__results li {
    cursor: pointer;
    padding: 8px;
  }
}
.suggester-label {
  color: #999;
  position: absolute;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 8px;
  top: 10px;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

// /deep/ .country-select label {
//   left: 8px;
// }
.suggester-label.active {
  top: -10px;
  font-size: 14px;
  color: #4dba87;
}
.autosuggest__results small {
  display: block;
  color: #9e9e9e;
  font-size: 0.8em;
}
.validate-circle {
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: 18px;
  top: 8px;
  left: auto;
  display: flex;
  padding: 2px;
  border: 1px solid;
  border-radius: 30px;
}
.not-valid {
  border-color: #d3d3d3;
  color: #d3d3d3;
}
.icon {
  right: 6px;
  top: 10px;
}
.label-active {
  position: absolute;
  top: -10px;
  font-size:14px;
  color:#ff5100;
}
.open-pickup-points {
  margin-left: 10px;
  background: #fff;
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 3px 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
  &:hover {
    background: #000;
    color: #fff;
  }
}
.get-nip-details {
  background: #fff;
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 3px 10px;
  text-transform: uppercase;
  font-size: 0.8rem;
  &:hover {
    background: #000;
    color: #fff;
  }
  &.disabled {
    border-color: #d3d3d3;
    color: #d3d3d3;
  }
}
.open-variable-butt {
  cursor: pointer;
}
.pickup-points {
  z-index: 1000;
}
.checkmark {
  top: 15px !important;
}
.mobile-footer-nav-but {
  &:disabled {
    background-color: #d9d9d9;
  }
}
</style>

import * as types from './store/mutation-types'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { getGuaClientId } from 'theme/utils/getGuaClientId'
import { getGuaUa } from 'theme/utils/getGuaUa'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import BlikCodeComponent from './components/BlikCode.vue'
import Vue from 'vue';

import config from 'config'

const PaymentPayUBlikStore = {
  namespaced: true,
  state: {
    methods: null,
    additional_payment_data: {
      payu_method: 'blik',
      payu_method_type: 'PBL',
      authorization_code: '',
      parcel_locker_no: ''
    }
  },
  mutations: {
    [types.SET_BACKEND_PAYMENT_METHODS] (state, paymentMethods) {
      state.methods = paymentMethods
    },
    [types.SET_AUTHORIZATION_CODE] (state, authorization_code) {
      state.additional_payment_data.authorization_code = authorization_code
    },
    [types.SET_GUA_CLIENT_ID] (state, gua_client_id) {
      state.additional_payment_data.gua_client_id = gua_client_id
    },
    [types.SET_GUA_UA] (state, gua_user_agent) {
      if (gua_user_agent) { state.additional_payment_data.gua_ua = gua_user_agent }
    },
    [types.SET_PARCEL_LOCKER_NO] (state, parcel_locker_no) {
      state.additional_payment_data.parcel_locker_no = parcel_locker_no
    }
  },
  getters: {
    getAdditionalPaymentData: state => state.additional_payment_data,
    getAuthorizationCode: state => state.additional_payment_data.authorization_code
  }
}

export const KEY = 'payment-payu-blik'

export const PaymentPayUBlikModule: StorefrontModule = function ({store}) {
  store.registerModule('payment-payu-blik', PaymentPayUBlikStore)

  let correctPaymentMethod = false

  const checkPaymentInformation = (payload) => {
    return fetch((config.payment_payu.endpoint.payment_information).replace('{{ backendOrderId }}', payload.confirmation.backendOrderId), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
  }

  const paymentComplete = (payload) => {
    store.dispatch('checkout/setThankYouPage', true)
    store.dispatch('user/getOrdersHistory', { refresh: true, useCache: true })
    Logger.debug(payload.order)()

    store.dispatch('notification/spawnNotification', {
      type: 'success',
      message: i18n.t('Your payment has been received'),
      action1: { label: i18n.t('OK') }
    })
  }

  const paymentCanceled = (payload) => {
    // store.dispatch('checkout/setThankYouPage', true)
    // store.dispatch('user/getOrdersHistory', { refresh: true, useCache: true })
    Logger.debug(payload.order)()

    store.dispatch('notification/spawnNotification', {
      type: 'error',
      message: i18n.t('Your payment has been canceled'),
      action1: { label: i18n.t('OK') }
    })
  }

  let timer = null

  const paymentResults = (data, payload) => {
    if (data.result && data.result[0].redirectUri) {
      window.location.href = data.result[0].redirectUri
    } else if (data.result && data.result[0].result === 'completed') {
      EventBus.$emit('blik-notification-progress-stop')
      EventBus.$emit('blik-notification-completed')
      clearInterval(timer)
      timer = null
      paymentComplete(payload)
    } else if (data.result && data.result[0].result === 'canceled') {
      EventBus.$emit('blik-notification-progress-stop')
      clearInterval(timer)
      timer = null
      paymentCanceled(payload)
    }
  }

  const afterPlaceOrder = (payload) => {
    if (correctPaymentMethod) {
      console.log('PaymentPayUBlikModule correctPaymentMethod ' + correctPaymentMethod)

      EventBus.$emit('blik-notification-progress-start', 'Teraz potwierdź płatność PINem za pomocą aplikacji w swoim telefonie')

      if (timer === null) {
        timer = setInterval(() => {
          checkPaymentInformation(payload).then(response => response.json())
            .then(data => paymentResults(data, payload))
        }, 5000)
      }
    }
  }

  // Place the order. Payload is empty as we don't have any specific info to add for this payment method '{}'
  const placeOrder = () => {
    // console.log('placeOrder in payment-payu-blik')
    if (correctPaymentMethod) {
      // console.log('true')
      let shippingDetails = store.getters['checkout/getShippingDetails'];
      store.commit(KEY + '/' + types.SET_PARCEL_LOCKER_NO, shippingDetails.pickupId)

      let additionalPaymentData = store.getters[KEY + '/' + 'getAdditionalPaymentData']
      EventBus.$off('order-after-placed')
      EventBus.$on('order-after-placed', afterPlaceOrder)

      console.log('PlaceOrder in PaymentPayUBlikModule')
      EventBus.$emit('checkout-do-placeOrder', additionalPaymentData)
    }
  }

  if (!isServer) {
    // Update the methods
    EventBus.$on('set-unique-payment-methods', methods => {
      store.commit('payment-backend-methods/' + types.SET_BACKEND_PAYMENT_METHODS, methods)
    })

    // Mount the info component when required
    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      let methods = store.state['payment-backend-methods'].methods
      if (paymentMethodCode === 'vue_payu_gateway_blik' && methods !== null && methods.find(item => (item.code === paymentMethodCode && item.is_server_method === true))) {
        correctPaymentMethod = true

        EventBus.$off('checkout-before-placeOrder')
        EventBus.$on('checkout-before-placeOrder', placeOrder)

        store.commit(KEY + '/' + types.SET_GUA_CLIENT_ID, getGuaClientId())
        store.commit(KEY + '/' + types.SET_GUA_UA, getGuaUa())
        const Component = Vue.extend(BlikCodeComponent)
        const componentInstance = (new Component({ store: store }))
        // @ts-ignore
        componentInstance._i18n = i18n
        componentInstance.$mount('#checkout-order-review-additional')
      } else {
        correctPaymentMethod = false
      }
    })

    EventBus.$on('order-after-placed', afterPlaceOrder)
  }
}

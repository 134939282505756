<template>
  <modal name="modal-pickup-points" :width="1184">
    <iframe
      src="https://pickuppointsmap.dev.beecommerce.pl/"
      scrolling="no"
      frameborder="no"
      width="100%"
      height="800px"
      allow="geolocation"
      @load="sendMessage"
      ref="pickuppoints"
    ><p>Your browser does not support iframes.</p></iframe>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from 'theme/components/core/Modal';
import rootStore from '@vue-storefront/core/store';

export default {
  name: 'PickupPoints',
  components: {
    Modal
  },
  props: {
    shipping: {
      type: Object,
      default: null
    }
  },
  updated () {
    this.sendMessage()
  },
  methods: {
    sendMessage () {
      if (this.$refs.pickuppoints) {
        this.$refs.pickuppoints.contentWindow.postMessage(
          {
            type: 'onclick',
            content: {
              filter: this.shipping.shippingMethod,
              address: this.shipping.city + ' ' + this.shipping.streetAddress + ' ' + this.shipping.zipCode,
              key: rootStore.state.config.apiKeys.pickupPoints
            }
          },
          'https://pickuppointsmap.dev.beecommerce.pl'
        );
      }
    }
  }
};
</script>

<style scoped>
.modal {
  font-size: 18px;
}
</style>

<template>
  <div>
    <header class="thank-you-title bg-cl-secondary py15 pl20">
      <div class="container">
        <breadcrumbs
          :routes="[{name: 'Homepage', route_link: '/'}]"
          :active-route="this.$t('Order confirmation')"
        />
        <!-- <h1 class="category-title">
          {{ $t('Order confirmation') }}
        </h1> -->
      </div>
    </header>
    <div class="thank-you-content align-justify py40 pl20">
      <div class="container p0">
        <div class="">
          <div class="col-md-12 p0 m0">
            <h1 v-show="OnlineOnly" class="align-center cl-orange m10">
              {{ $t('Thank you for purchase!') }}
            </h1>
            <p v-show="OnlineOnly && lastOrderConfirmation" class="m20 p0 align-center weight-700" v-html="this.$t('Your Order number: ') + '#' + lastOrderConfirmation.orderNumber" />
            <p v-show="OnlineOnly" class="mobile-p10">
              {{ $t('You have successfuly placed the order. You can check status of your order by using our ') }}<b @click="goToAccount()" class="pointer">{{ $t('delivery status') }}</b>{{ $t(' feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.') }} {{ $t('Jeśli po kilku minutach wciąż nie widzisz potwierdzenia na swojej skrzynce mailowej, sprawdź folder SPAM.') }}
            </p>
            <!-- <p v-if="OnlineOnly" v-html="this.$t('E-mail us at <b>info@streetsupply.pl</b> with any questions, suggestions how we could improve products or shopping experience')"/> -->

            <h4 v-show="OfflineOnly">
              {{ $t('You are offline') }}
            </h4>
            <p v-show="OfflineOnly && !isNotificationSupported" class="mobile-p10">
              {{ $t('To finish the order just come back to our store while online. Your order will be sent to the server as soon as you come back here while online and then confirmed regarding the stock quantities of selected items') }}
            </p>
            <p v-show="OfflineOnly && isNotificationSupported && !isPermissionGranted" class="mobile-p10">
              {{ $t("You can allow us to remind you about the order via push notification after coming back online. You'll only need to click on it to confirm.") }}
            </p>
            <p v-show="OfflineOnly && isNotificationSupported && isPermissionGranted" class="mobile-p10">
              <strong>{{ $t('You will receive Push notification after coming back online. You can confirm the order by clicking on it') }}</strong>
            </p>
            <!-- <p v-if="!isPermissionGranted && isNotificationSupported">
              <button-outline color="dark" @click.native="requestNotificationPermission()" >
                {{ $t('Allow notification about the order') }}
              </button-outline>
            </p> -->
            <div id="thank-you-extensions" />
            <h3 @click="goToAccount()" class="pointer">
              {{ $t('Your Account') }}
            </h3>
            <p class="mobile-p10">
              {{ $t('You can log to your account using e-mail and password defined earlier. On your account you can ') }}
              <b @click="goToAccount()" class="pointer">{{ $t('edit your profile data,') }}</b>
              {{ $t(' check ') }}
              <b @click="goToAccount()" class="pointer">{{ $t('history of transactions') }}</b>
              {{ $t(' edit ') }}
              <b @click="goToAccount()" class="pointer">{{ $t('subscription to newsletter.') }}</b>
            </p>
          </div>
          <div class="col-md-12 row space-between p0 m0">
            <div class="typ-summary col-lg-6 col-xs-12 p0 m0">
              <div class="data-wrapper col-xs-12 p0 m0" v-if="orderDetails && Object.keys(orderDetails).length">
                <div class="col-md-6 col-xs-12 p0 customer-data">
                  <h3 class="m0 py15 px5 bg-cl-secondary">
                    {{ $t('Customer Details') }}:
                  </h3>
                  <div class="py10">
                    <p class="m0 px10">
                      {{ orderDetails.payment.firstName }} {{ orderDetails.payment.lastName }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.payment.streetAddress }} {{ orderDetails.payment.apartmentNumber ? `/${orderDetails.payment.apartmentNumber}` : '' }}<br>
                      {{ orderDetails.payment.zipCode }} {{ orderDetails.payment.city }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.payment.type !== 'Osoba prywatna' ? 'Faktura' : 'Paragon' }}
                    </p>
                    <template v-if="orderDetails.payment.type !== 'Osoba prywatna'">
                      <p class="m0 px10">
                        {{ orderDetails.payment.company && orderDetails.payment.company }}
                      </p>
                      <p class="m0 px10">
                        {{ $t('Tax number') }}: {{ orderDetails.payment.customTaxId && orderDetails.payment.customTaxId }}
                      </p>
                    </template>
                    <p class="m0 px10">
                      {{ orderDetails.payment.emailAddress }}
                    </p>
                    <p class="m0 px10">
                      {{ $t('Phone.') }} {{ orderDetails.payment.phoneNumber }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12 p0 customer-data">
                  <h3 class="m0 py15 px5 bg-cl-secondary shipping-data">
                    {{ $t('Delivery details') }}:
                  </h3>
                  <div class="py10">
                    <p class="m0 px10">
                      {{ orderDetails.shipping.firstName }} {{ orderDetails.shipping.lastName }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.shipping.company && orderDetails.shipping.company }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.shipping.streetAddress }} {{ orderDetails.shipping.apartmentNumber }} {{ orderDetails.shipping.flatNumber ? `/${orderDetails.shipping.flatNumber}` : '' }}<br>
                      {{ orderDetails.shipping.zipCode }} {{ orderDetails.shipping.city }}
                    </p>
                    <p class="m0 px10">
                      {{ $t('Phone.') }} {{ orderDetails.shipping.phoneNumber }}
                    </p>
                    <template v-for="(total, index) in cardTotals">
                      <p class="m0 px10" v-show="total.code === 'shipping'" :key="index">
                        {{ $t(total.title) }}
                      </p>
                      <p class="m0 px10" v-show="total.code === 'grand_total'" :key="10 + index">
                        {{ $t('Order Value') }}: {{ total.value | price }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-lg-12 m0 p0">
                <h3 class="m0 py15 px5 bg-cl-secondary order">
                  {{ $t('Your order') }}:
                </h3>
                <div v-for="prod in cardProducts" class="card-product p10 row between-md between-xs" :key="prod.sku">
                  <div class="card-product-name">
                    <span class="cl-alternative weight-600">
                      {{ prod.name }}<br>
                    </span>
                    <div v-for="opt in prod.totals.options" :key="opt.label">
                      <span class="opn ff-semi cl-tertiary">{{ $t(opt.label) }}: </span>
                      <span class="opv ff-light cl-tertiary" v-html="opt.value" />
                    </div>
                  </div>
                  <div>
                    <div v-if="!prod.totals" class="text-right">
                      <span class="cl-error" v-if="prod.special_price">{{ prod.qty }} x {{ prod.price_incl_tax | price }} </span><br>
                      <span class="price-original cl-accent h5" v-if="prod.special_price">
                        {{ prod.qty }} x {{ prod.original_price_incl_tax | price }}
                      </span><br>
                      <span v-if="!prod.special_price" class="h4 cl-black">{{ prod.qty }} x {{ prod.price_incl_tax | price }}</span>
                    </div>
                    <div v-if="prod.totals" class="text-right opv ff-light cl-tertiary">
                      <span class="cl-error" v-if="prod.totals.discount_amount">{{ prod.qty }} x {{ prod.totals.row_total_incl_tax - prod.totals.discount_amount | price }} </span><br>
                      <!-- <span class="price-original h5 cl-black" v-if="prod.totals.discount_amount">
                        {{ prod.qty }} x {{ prod.totals.row_total_incl_tax | price }}
                      </span> -->
                      <span v-if="!prod.totals.discount_amount" class="h4 cl-black">{{ prod.qty }} x {{ prod.totals.row_total_incl_tax | price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xs-12 bg-cl-secondary thank-you-improvment">
              <h3 class="m0 py15">
                {{ $t('What we can improve?') }}
              </h3>
              <p class="mb25">
                {{ $t('Your feedback is important for us. Let us know what we could improve.') }}
              </p>
              <form @submit.prevent="sendFeedback">
                <base-textarea
                  class="mb25"
                  type="text"
                  name="body"
                  v-model="feedback"
                  :placeholder="$t('Type your opinion')"
                  :autofocus="true"
                />
                <button-outline color="dark">
                  {{ $t('Give a feedback') }}
                </button-outline>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Composite from '@vue-storefront/core/mixins/composite'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import VueOfflineMixin from 'vue-offline/mixin'
import { EmailForm } from '@vue-storefront/core/modules/mailer/components/EmailForm'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { MailerModule } from '@vue-storefront/core/modules/mailer'
import { mapState, mapGetters } from 'vuex'
// import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  name: 'ThankYouPage',
  mixins: [Composite, VueOfflineMixin, EmailForm],
  beforeCreate () {
    registerModule(MailerModule)
  },
  data () {
    return {
      feedback: '',
      wasLoad: false,
      cardTotals: '',
      cardProducts: '',
      orderDetails: '',
      responseDetails: '',
      isCOD: false
    }
  },
  mounted () {
    this.$bus.$on('on-after-place-order', this.setCodTrue)
    this.$bus.$on('on-after-place-order', this.thankYouPagePush)
    this.$bus.$on('blik-notification-completed', this.thankYouPagePush)
    this.$bus.$on('test-order-placed', this.getDataOnOrder)
    this.getDataOnOrder()
  },
  beforeDestroy () {
    this.$bus.$off('on-after-place-order', this.setCodTrue)
    this.$bus.$off('on-after-place-order', this.thankYouPagePush)
    this.$bus.$off('blik-notification-completed', this.thankYouPagePush)
    this.$bus.$off('test-order-placed', this.getDataOnOrder)
    localStorage.removeItem('OrderDetails')
    localStorage.removeItem('CardProducts')
    localStorage.removeItem('CardTotals')
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    ...mapGetters({
      totals: 'cart/getTotals'
    }),
    lastOrderConfirmation () {
      return this.$store.state.order.last_order_confirmation ? this.$store.state.order.last_order_confirmation.confirmation : {}
    },
    isNotificationSupported () {
      if (isServer || !('Notification' in window)) return false
      return 'Notification' in window
    },
    isPermissionGranted () {
      if (isServer || !('Notification' in window)) return false
      return Notification.permission === 'granted'
    },
    checkoutPersonalEmailAddress () {
      return this.$store.state.checkout.personalDetails.emailAddress
    },
    mailerElements () {
      return this.$store.state.config.mailer.contactAddress
    },
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    },
    user () {
      return this.$store.state.user.current
    }
  },
  methods: {
    setCodTrue () {
      this.isCOD = true
    },
    getDataOnOrder () {
      this.cardTotals = JSON.parse(localStorage.getItem('CardTotals')) || []
      this.cardProducts = JSON.parse(localStorage.getItem('CardProducts')) || []
      this.orderDetails = JSON.parse(localStorage.getItem('OrderDetails')) || []
      this.responseDetails = JSON.parse(localStorage.getItem('ResponseDetails')) || []
      if (this.responseDetails && Object.keys(this.responseDetails).length && this.orderDetails && !Object.keys(this.orderDetails).length) {
        this.orderDetails = this.setResponseDetailsAsOrderDetails()
        this.thankYouPagePush()
      }
    },
    setResponseDetailsAsOrderDetails () {
      const responseDetails = this.responseDetails

      if (responseDetails.shippingAddress) {
        const orderData = {
          shipping: {
            firstName: responseDetails.shippingFirstName ? responseDetails.shippingFirstName : '',
            lastName: responseDetails.shippingLastName ? responseDetails.shippingLastName : '',
            streetAddress: responseDetails.shippingAddress.addressLine[0],
            apartmentNumber: responseDetails.shippingAddress.addressLine[1],
            zipCode: responseDetails.shippingAddress.postalCode,
            city: responseDetails.shippingAddress.city,
            company: responseDetails.shippingAddress.organization ? responseDetails.shippingAddress.organization : '',
            emailAddress: responseDetails.payerEmail,
            phoneNumber: responseDetails.shippingAddress.phone
          },
          payment: {
            firstName: responseDetails.shippingFirstName ? responseDetails.shippingFirstName : '',
            lastName: responseDetails.shippingLastName ? responseDetails.shippingLastName : '',
            streetAddress: responseDetails.shippingAddress.addressLine[0],
            apartmentNumber: responseDetails.shippingAddress.addressLine[1],
            flatNumber: '',
            zipCode: responseDetails.shippingAddress.postalCode,
            city: responseDetails.shippingAddress.city,
            company: responseDetails.shippingAddress.organization ? responseDetails.shippingAddress.organization : '',
            phoneNumber: responseDetails.shippingAddress.phone
          }
        }
        return orderData
      }
    },
    thankYouPagePush () {
      let orderId = this.lastOrderConfirmation.orderNumber
      let grand_total, tax, shipping, coupon
      if (this.cardTotals) {
        this.cardTotals.forEach((item) => {
          if (item.code === 'grand_total') {
            grand_total = item.value
          } else if (item.code === 'tax') {
            tax = item.value
          } else if (item.code === 'shipping') {
            shipping = item.value
          }
        })
      }
      let products = this.cardProducts && this.cardProducts.length ? this.cardProducts.map((item) => {
        return { childSku: item.sku, id: item.parentSku, name: item.name, price: item.final_price, fbId: item.id, quantity: 1 }
      }) : ''
      this.wasLoad = true
      window.dataLayer.push({
        event: 'uaevent',
        eventCategory: 'ecomm',
        eventAction: 'Purchase',
        ceneoConsent: this.orderDetails.ceneoConsent ? 1 : 0,
        shippingEmail: this.checkoutPersonalEmailAddress,
        ecomm: {
          purchase: {
            id: orderId,
            payment_result: 'completed',
            currency: 'PLN',
            actionField: {
              id: orderId,
              affiliation: 'Online Store',
              revenue: this.isCOD ? grand_total + 4 : grand_total,
              tax: tax,
              shipping: shipping
            },
            products
          }
        }
      });
    },
    goToAccount () {
      if (this.currentUser) {
        this.$router.push(this.localizedRoute('/my-account'))
      } else {
        this.$bus.$emit('modal-show', 'modal-signup')
      }
    },
    requestNotificationPermission () {
      if (isServer) return false
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    },
    async sendFeedback () {
      let splittedMailerElements = this.mailerElements.split(',')
      for (let i = 0; i < splittedMailerElements.length; i++) {
        await this.sendEmail({
          sourceAddress: this.checkoutPersonalEmailAddress,
          targetAddress: splittedMailerElements[i],
          subject: this.$t('What we can improve?'),
          emailText: this.feedback
        },
        this.onSuccess,
        this.onFailure
        )
      }
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message,
        action1: { label: this.$t('OK') }
      })
      if (this.mailerElements.sendConfirmation) {
        this.sendEmail(
          {
            sourceAddress: this.mailerElements,
            targetAddress: this.checkoutPersonalEmailAddress,
            subject: this.$t('Confirmation of receival'),
            emailText: this.$t(`Dear customer,\n\nWe have received your letter.\nThank you for your feedback!`),
            confirmation: true
          }
        )
      }
    },
    onFailure (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message,
        action1: { label: this.$t('OK') }
      })
    }
  },
  destroyed () {
    this.$store.dispatch('checkout/setThankYouPage', false)
  },
  components: {
    BaseTextarea,
    Breadcrumbs,
    ButtonOutline
  }
}
</script>

<style lang="scss">
  .thank-you-content {
    padding-left: 0;

    p {
      line-height: 25px
    }

    .shipping-data, .order {
      margin-right: 10px;
    }
    @media (min-width: 64em) {
      h4 {
        font-size: 24px;
      }
    }
    @media (max-width: 767px) {
      h3,
      .account-header {
        text-align: center;
      }
    }
    @media (max-width: 991px) {
      .account-header {
        padding-left: 5px;
        padding-right: 5px;
        margin: 10px
      }
      .mobile-p10 {
        padding: 10px;
      }
      .shipping-data, .order {
        margin-right: 0;
      }
    }
  }
  .thank-you-improvment {
    padding: 0 20px 15px;

    // @media (min-width: 64em) {
    //   padding: 0 40px 10px;
    // }

    textarea {
      min-height: 100px;
    }
  }
  .typ-summary {
    .data-wrapper {
      display: flex;
      flex-direction: row;
    }
    .card-product {
      margin: 0px;
      .opn,
      .opv {
        font-size: 0.8em;
      }
      .card-product-name {
        max-width: 60%;
      }
    }
    @media (max-width: 767px) {
      .data-wrapper {
        flex-direction: column;
      }
      .card-product {
        margin: 0 5px;
        font-size: 0.9em;
      }
    }
  }
  @media (max-width: 768px) {
    .back-to-shop {
      width: 100%;
    }
    .thank-you-title {
      padding: 20px 10px 5px;
    }
    .thank-you-content {
      padding-top: 0;
    }
  }
</style>

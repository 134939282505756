<template>
  <div class="" :class="{ 'mb-xs-100' : isActive }">
    <div class="row pl20 hidden-xs step-title">
      <div class="col-xs-1 col-sm-2 col-md-1 p0">
        <div
          class="number-circle lh35 brdr-1 brdr-cl-suva-gray brdr-circle align-center weight-700"
          :class="{ 'bg-cl-primary-orange cl-primary-orange' : isActive, 'bg-cl-white cl-white' : !isFilled && !isActive, 'bg-cl-suva-gray cl-suva-gray' : isFilled }"
        >
          2
        </div>
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row">
          <div class="col-xs-12 col-md-5" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <p class="m0 h4 lh30 weight-700 uppercase">
              {{ $t('Shipping') }}
            </p>
          </div>
          <div class="col-xs-12 col-md-7 pr30">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary h5 flex" @click.prevent="edit">
                <span class="pr5">
                  {{ $t('Edit shipping') }}
                </span>
                <img :src="'/assets/svg/create.svg'" class="filter-gray" width="26px" height="26px" alt="edit">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-10 p-0">
        <div class="row">
          <p class="col-xs-12 h6 mobile-label-header">
            {{ $t('Shipping method') }}
          </p>
          <div v-for="(method, index) in shippingMethods" :key="index" class="col-md-6 p-0-20-xs">
            <label class="radioStyled cl-black"> {{ $t(shippingMethod(method.method_title)) }} | {{ method.amount | price }}
              <input
                type="radio"
                :value="method.method_code"
                name="shipping-method"
                v-model="shipping.shippingMethod"
                @change="$v.shipping.shippingMethod.$touch(); changeShippingMethod();"
              >
              <span class="checkmark" />
            </label>
          </div>
          <span class="validation-error" v-if="$v.shipping.shippingMethod.$error && !$v.shipping.shippingMethod.required">
            {{ $t('Field is required') }}
          </span>
          <p class="col-xs-12 h6 mobile-label-header">
            {{ $t('Adres dostawy') }}
          </p>
          <template v-if="isOpen.inputs">
            <base-checkbox
              v-if="currentUser && hasShippingDetails()"
              class="col-xs-12 mb25 shipToMyAddressCheckbox"
              id="shipToMyAddressCheckbox"
              @click="useMyAddress"
              v-model="shipToMyAddress"
            >
              {{ $t('Ship to my default address') }}
            </base-checkbox>

            <base-input
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="first-name"
              :placeholder="$t('First name *')"
              v-model.trim="shipping.firstName"
              @blur="$v.shipping.firstName.$touch()"
              autocomplete="given-name"
              :validations="[
                {
                  condition: $v.shipping.firstName.$error && !$v.shipping.firstName.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.firstName.minLength,
                  text: $t('Name must have at least 3 letters.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="last-name"
              :placeholder="$t('Last name *')"
              v-model.trim="shipping.lastName"
              @blur="$v.shipping.lastName.$touch()"
              autocomplete="family-name"
              :validations="[{
                condition: $v.shipping.lastName.$error && !$v.shipping.lastName.required,
                text: $t('Field is required')
              }]"
            />

            <base-input
              class="col-xs-6 mb25"
              type="text"
              name="street-address"
              :placeholder="$t('Street name *')"
              v-model.trim="shipping.streetAddress"
              @blur="$v.shipping.streetAddress.$touch()"
              autocomplete="address-line1"
              :validations="[
                {
                  condition: $v.shipping.streetAddress.$error && !$v.shipping.streetAddress.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.streetAddress.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 mb25"
              type="text"
              name="apartment-number"
              :placeholder="$t('House/Apartment number *')"
              v-model.trim="shipping.apartmentNumber"
              @blur="$v.shipping.apartmentNumber.$touch()"
              autocomplete="address-line2"
              :validations="[
                {
                  condition: !$v.shipping.apartmentNumber.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="city"
              :placeholder="$t('City *')"
              v-model.trim="shipping.city"
              @blur="$v.shipping.city.$touch()"
              autocomplete="address-level2"
              :validations="[
                {
                  condition: $v.shipping.city.$error && !$v.shipping.city.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.city.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <!-- <base-input
              class="col-xs-12 col-sm-6 mb25"
              type="text"
              name="state"
              :placeholder="$t('State / Province')"
              v-model.trim="shipping.state"
              autocomplete="address-level1"
            /> -->

            <base-input
              class="col-xs-6 col-sm-6 mb25"
              type="text"
              name="zip-code"
              :placeholder="$t('Zip-code *')"
              v-model.trim="shipping.zipCode"
              @blur="$v.shipping.zipCode.$touch()"
              autocomplete="postal-code"
              :validations="[
                {
                  condition: $v.shipping.zipCode.$error && !$v.shipping.zipCode.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.zipCode.minLength,
                  text: $t('Value is to short.')
                },
                {
                  condition: !$v.shipping.zipCode.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <base-select
              class="col-xs-6 col-sm-6 mb25"
              name="countries"
              :options="temporaryCountryOptions"
              :selected="shipping.country"
              :placeholder="$t('Country *')"
              :validations="[
                {
                  condition: $v.shipping.country.$error && !$v.shipping.country.required,
                  text: $t('Field is required')
                }
              ]"
              v-model="shipping.country"
              autocomplete="country-name"
              @blur="$v.shipping.country.$touch()"
              @change="$v.shipping.country.$touch(); changeCountry();"
            />

            <base-input
              class="col-xs-6 mb25"
              type="text"
              name="phone-number"
              :placeholder="$t('Phone Number')"
              v-model.trim="shipping.phoneNumber"
              autocomplete="tel"
            />
            <div id="close-variable-butt" class="col-xs-12 close-variable-butt" @click="toggleDiv">
              Zakończ edycję
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20" v-if="isActive && isOpen.labels">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11 p-0">
        <div class="row fs16 mb35 p-0-20-xs m-0-lg">
          <div class="col-xs-12 h4 p-0" data-testid="shippingAddressSummary">
            <div id="open-variable-butt" class="open-variable-butt" @click="toggleDiv">
              <img :src="'/assets/svg/create.svg'" class="edit-icon" width="26px" height="26px" alt="edit">
            </div>
            <p class="m0 label-text">
              {{ shipping.firstName }} {{ shipping.lastName }}
            </p>
            <p class="m0 label-text">
              {{ shipping.streetAddress }} {{ shipping.apartmentNumber }}
            </p>
            <p class="m0 label-text">
              {{ shipping.city }} {{ shipping.zipCode }}
            </p>
            <p class="m0 label-text">
              <span v-if="shipping.state">{{ shipping.state }}, </span>
              <span>{{ getCountryName() }}</span>
            </p>
            <p v-if="shipping.phoneNumber" class="m0 label-text">
              <span class="pr15">{{ shipping.phoneNumber }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11 p-0">
        <p class="mobile-label-header h6">
          {{ $t('Dane do faktury') }}
        </p>
      </div>
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11 p-0">
        <div class="row" v-if="isActive">
          <base-checkbox
            class="col-xs-12 col-sm-9 col-md-11 mb15 p-0-20-xs generateInvoiceCheckbox"
            id="generateInvoiceCheckbox"
            v-model="generateInvoice"
            @click="useGenerateInvoice"
          >
            {{ $t('I want to generate an invoice for the company') }}
          </base-checkbox>

          <template v-if="generateInvoice">
            <!-- <base-input
              class="col-xs-12 col-sm-9 col-md-11 mb25 p-0-20-xs"
              type="text"
              name="company-name"
              :placeholder="$t('Company name *')"
              v-model.trim="shipping.company"
              @blur="$v.shipping.company.$touch()"
              autocomplete="organization"
              :validations="[{
                condition: $v.shipping.company.$error && !$v.shipping.company.required,
                text: $t('Field is required')
              }]"
            /> -->

            <base-input
              class="col-xs-12 col-sm-9 col-md-11 mb25 p-0-20-xs"
              type="text"
              name="tax-id"
              :placeholder="$t('Tax identification number *')"
              v-model.trim="shipping.taxId"
              @blur="$v.shipping.taxId.$touch()"
              autocomplete="tax-id"
              :validations="[
                {
                  condition: $v.shipping.taxId.$error && !$v.shipping.taxId.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.shipping.taxId.minLength || !$v.shipping.taxId.maxLength,
                  text: $t('Tax identification number must have 10 letters.')
                }
              ]"
            />

            <div class="col-xs-12 col-sm-9 col-md-11 mb25 p-0-20-xs">
              <label class="fs16 cl-secondary">
                {{ $t('We will send you the invoice to given e-mail address') }}
              </label>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="row mobile-footer-nav" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11" @click="changeFilled">
        <div class="row">
          <div class="col-xs-12 col-md-8 my30 m-xs-0 px20">
            <button-full
              data-testid="shippingSubmit"
              @click.native="sendDataToCheckout"
              :disabled="$v.shipping.$invalid"
              class="mobile-footer-nav-but"
              :class="{ 'disable-but' : !isFilled && !isActive }"
            >
              {{ $t('Continue to payment') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { Shipping } from '@vue-storefront/core/modules/checkout/components/Shipping'
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  data () {
    return {
      temporaryCountryOptions: [{ 'value': 'PL', 'label': 'Poland' }],
      isOpen: { labels: true, inputs: false },
      addManually: false
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput,
    BaseSelect
  },
  mounted () {
    this.changeFilled()
  },
  methods: {
    setAddManually () {
      this.addManually = true
    },
    shippingMethod (id) {
      if (id === 'flatrate') {
        return 'Flatrate'
      } else if (id === 'ups_fixed') {
        return 'Ups'
      } else {
        return id
      }
    },
    toggleDiv () {
      if (this.isOpen.labels === true) {
        this.isOpen.labels = false
        this.isOpen.inputs = true
      } else {
        this.isOpen.labels = true
        this.isOpen.inputs = false
      }
    },
    changeFilled () {
      this.$emit('shippingUpdate', this.isFilled)
    }
  },
  mixins: [Shipping],
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  validations () {
    if (!this.generateInvoice) {
      return {
        shipping: {
          firstName: {
            required,
            minLength: minLength(3),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          country: {
            required
            // mustBeCool
          },
          streetAddress: {
            required,
            maxLength: maxLength(60),
            unicodeAlphaNum
          },
          apartmentNumber: {
            maxLength: maxLength(10)
          },
          shippingMethod: {
            required,
            unicodeAlpha
          },
          zipCode: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20),
            unicodeAlphaNum
          },
          city: {
            required,
            maxLength: maxLength(20),
            minLength: minLength(3),
            unicodeAlpha
          }
        }
      }
    } else if (this.addManually) {
      return {
        shipping: {
          firstName: {
            required,
            minLength: minLength(3),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          country: {
            required
            // mustBeCool
          },
          streetAddress: {
            required,
            maxLength: maxLength(60),
            unicodeAlphaNum
          },
          apartmentNumber: {
            maxLength: maxLength(10)
          },
          shippingMethod: {
            required
          },
          zipCode: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20),
            unicodeAlphaNum
          },
          city: {
            required,
            maxLength: maxLength(20),
            minLength: minLength(3),
            unicodeAlpha
          },
          company: {
            required,
            minLength: minLength(3)
          },
          taxId: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10)
          },
          regon: {
            required,
            minLength: minLength(3)
          },
          nipCity: {
            required,
            maxLength: maxLength(20)
          },
          street: {
            required,
            maxLength: maxLength(60)
          },
          nipZipCode: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20)
          }
        }
      }
    } else {
      return {
        shipping: {
          firstName: {
            required,
            minLength: minLength(3),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          country: {
            required
            // mustBeCool
          },
          streetAddress: {
            required,
            maxLength: maxLength(60),
            unicodeAlphaNum
          },
          apartmentNumber: {
            maxLength: maxLength(10)
          },
          shippingMethod: {
            required
          },
          zipCode: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20),
            unicodeAlphaNum
          },
          city: {
            required,
            maxLength: maxLength(20),
            minLength: minLength(3),
            unicodeAlpha
          },
          taxId: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-gray {
  filter: contrast(0.3);
}
  // .number-circle {
  //   background-color: #E74141;
  //   color: #E74141;
  //   border: 1px solid #CFCED8;
  // }
  .mobile-footer-nav-but {
    font-weight: 500;
  }
  .step-title {
    @media (min-width: 768px) {
      display: flex;
    }
    align-items: center;
    margin-bottom: 15px;
    padding-left: 27px;
  }
  .pl-sm-20 {
    padding-left: 20px;
  }
  @media (min-width: 768px) {
  .mb25 {
      margin-bottom: 0px;
  }
  .m-0-lg {
    margin-left: 0px;
    margin-right: 0px;
  }
  }
  @media (max-width: 767px) {
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .pl-sm-20 {
      padding-left: 0px;
    }
    .mobile-footer-nav {
      position: fixed;
      background: rgb(255, 255, 255);
      width: 100%;
      padding: 20px 30px;
      height: 80px;
      left: 0px;
      bottom: 0px;
      border-top: 1px solid rgb(206, 206, 206);
      z-index: 999;
      box-sizing: border-box;
    }
    .mobile-footer-nav-but {
      height: 40px;
      padding: 0;
      font-size: 16px;
    }
    .disable-but {
      background-color: #d9d9d9;
    }
    .m-xs-0 {
      margin: 0;
    }
    .mb-xs-100 {
      margin-bottom: 100px;
    }
    .mobile-label-header {
      margin-top:0;
      padding: 7px 0px 7px 16px;
      background-color: #000000;
      color: #FFFFFF;
      text-transform: uppercase;
    }
    .radioStyled {
      margin-bottom: 18px !important;
    }
    .label-text {
      color: #4D4D4D;
      font-size: 16px;
    }
    .edit-icon {
      position: absolute;
      bottom: auto !important;
      right: 20px;
      color:#A5A5A5;
    }
    .close-variable-butt {
      font-size: 12px;
      font-weight: bold;
      color: #041D59;
      text-align: right;
      width: 100%;
      padding-bottom: 10px;
    }
    .p-0 {
     padding: 0;
    }
    .p-0-20-xs {
      padding: 0 20px;
    }
  }
   .edit-icon {
      position: absolute;
      // bottom: 300px;
      right: 20px;
      color:#A5A5A5;
      cursor: pointer;
    }
    .close-variable-butt {
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      color: #041D59;
      text-align: right;
      width: 100%;
    }
    .p-0 {
     padding: 0;
    }
</style>

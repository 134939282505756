<template>
  <div class="payment">
    <div class="row pl20 hidden-xs step-title">
      <div class="col-xs-1 col-sm-2 col-md-1 p0">
        <div
          class="number-circle lh35 brdr-1 brdr-cl-tertiary brdr-circle align-center weight-700 bg-cl-white cl-tertiary"
          :class="{
            'bg-cl-alternative brdr-cl-alternative active' : isActive,
            'bg-cl-tertiary cl-tertiary' : !isFilled && !isActive,
            'bg-cl-tertiary cl-tertiary' : isFilled && !isActive
          }"
        />
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row">
          <div
            class="col-xs-12 between-sm flex middle-sm pointer"
          >
            <p class="m0 h4 lh30 weight-700 uppercase">
              {{ $t('Payment') }}
            </p>
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-accent h5 flex edit-payment" @click.prevent="edit">
                <span class="pr5">{{ $t('Edit payment') }}</span>
                <img :src="'/assets/svg/create.svg'" class="filter-gray" width="26px" height="26px" alt="edit">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-sm-0" v-if="isActive">
      <div class="hidden-xs col-sm-2" />
      <div class="col-xs-12 col-sm-10 p-0">
        <div class="row" v-if="isActive">
          <div class="col-xs-12 p-0">
            <p class="mobile-label-header h6">
              {{ $t('Payment method') }}
            </p>
          </div>
          <div
            v-for="(method, index) in visiblePaymentMethods"
            :key="index"
            class="col-md-12 p-0-20-xs"
            :class="
              method.code === 'cashondelivery' && !isCashOnDeliveryAvailable
                ? 'disabled'
                : ''
            "
          >
            <label class="radioStyled wrap radio-payment">
              <img v-if="method.code === 'vue_payu_gateway_blik'" src="/assets/blik_logo.svg" alt="blik logo" height="25px" width="auto" class="mr10">
              <img v-if="method.code === 'vue_payu_gateway'" src="/assets/PayU_logo.svg" alt="payu logo" height="35px" width="auto" class="">
              <img v-if="method.code === 'vue_payu_gateway_pay_card'" src="/assets/visa_logo.svg" alt="visa logo" height="30px" width="auto" class="mr10">
              <img v-if="method.code === 'vue_payu_gateway_pay_card'" src="/assets/mc_symbol.svg" alt="mc logo" height="30px" width="auto" class="mr10">
              <img v-if="method.code === 'vue_payu_gateway_pay_twisto'" src="/assets/twisto.svg" alt="twisto logo" height="25px" width="auto" class="mr10">
              <img v-if="method.code === 'vue_payu_gateway_pay_raty'" src="/assets/payuraty.svg" alt="raty logo" height="30px" width="auto" class="mr10">
              <img v-if="method.code === 'cashondelivery'" src="/assets/za_pobraniem.svg" alt="cashondelivery logo" height="30px" width="auto" class="mr10">
              <img v-if="method.code === 'banktransfer'" src="/assets/przelew_tradycyjny.svg" alt="banktransfer logo" height="30px" width="auto" class="mr10">
              <span class="method-label">{{ labelForPayment(method.code) }}</span>
              <router-link v-if="method.code === 'vue_payu_gateway_pay_twisto'" class="cl-alternative" target="_blank" :to="localizedRoute('/placepozniej')" exact> <i class="ml5 icon-info" /></router-link>
              <input
                type="radio"
                :value="method.code"
                name="payment-method"
                v-model="payment.paymentMethod"
                @change="
                  $v.payment.paymentMethod.$touch();
                  changePaymentMethod();
                "
                :disabled="
                  method.code === 'cashondelivery' && !isCashOnDeliveryAvailable
                "
              >
              <span class="checkmark" />
              <br><small
                v-if="
                  method.code === 'cashondelivery' && !isCashOnDeliveryAvailable
                    ? 'disabled'
                    : ''
                "
                class="small-info"
              >{{ $t("The selected pickup point does not accept cash on delivery.") }}</small>
            </label>
          </div>

          <span
            class="validation-error"
            v-if="!$v.payment.paymentMethod.required"
          >{{ $t('Field is required') }}</span>
        </div>
      </div>
    </div>
    <div class="row mobile-footer-nav" v-if="isActive">
      <div class="hidden-xs col-sm-2" />
      <div class="col-xs-12 col-sm-9 save-bottom" @click="changeFilled">
        <div class="row">
          <div class="col-xs-12 col-md-8 my30 m-xs-0 flex middle-xs center-xs">
            <button-full
              @click.native="sendDataToCheckout"
              data-testid="paymentSubmit"
              :disabled="$v.payment.$invalid"
              class="mobile-footer-nav-but"
              :class="{ 'disable-but': !isFilled && !isActive }"
            >
              {{ $t('Go review the order') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'
import { Payment } from '@vue-storefront/core/modules/checkout/components/Payment';
import ButtonFull from 'theme/components/theme/ButtonFull';
import rootStore from '@vue-storefront/core/store';
import config from 'config'

const stringRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return (/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).test(value);
};

const postCodeRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /\d{2}-\d{3}/g.test(value);
};

export default {
  components: {
    ButtonFull
  },
  mixins: [Payment],
  data () {
    return {
      isCashOnDeliveryAvailable: true
    };
  },
  created () {
    this.payment.paymentMethod = this.visiblePaymentMethods.length > 0 ? this.visiblePaymentMethods[0].code : 'cashondelivery'

    /*
    if (!window.ApplePaySession) {
      let applePayIndex = config.orders.visible_payment_methods.indexOf('vue_payu_gateway_applepay')
      config.orders.visible_payment_methods.splice(applePayIndex, 1)
    }
    */
  },
  beforeMount () {
    this.$bus.$on('checkout-after-shippingDetails', this.onAfterShippingDetails)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-shippingDetails', this.onAfterShippingDetails)
  },
  computed: {
    ...mapState({
      personalDetails: state => state.checkout.personalDetails,
      selectedCheckoutType: state => state.ui.selectedCheckoutType
    }),
    ...mapGetters({
      getTotals: 'cart/getTotals'
    }),
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    visiblePaymentMethods () {
      let grandTotal = this.getTotals && this.getTotals.find(el => el.code === 'grand_total')
      return grandTotal.value > 1500
        ? this.paymentMethods.filter(value => config.orders.visible_payment_methods.includes(value.code) && value.code !== 'vue_payu_gateway_pay_twisto')
        : this.paymentMethods.filter(value => config.orders.visible_payment_methods.includes(value.code))
    }
  },
  updated () {
    if (
      (this.shippingDetails.shippingMethod === 'In Post' ||
        this.shippingDetails.shippingMethod === 'Paczka w Ruchu') &&
      window.globalDataWidget
    ) {
      this.isCashOnDeliveryAvailable = false;
    } else {
      this.isCashOnDeliveryAvailable = true;
    }
  },
  mounted () {
    this.changeFilled()
  },
  methods: {
    labelForPayment (code) {
      if (code === 'cashondelivery') {
        return 'Za pobraniem | 3 PLN'
      } else if (code === 'pay_in_store') {
        return 'Płatność kartą lub gotówką w salonie'
      } else if (code === 'vue_payu_gateway_blik') {
        return 'Płacę BLIKiem'
      } else if (code === 'vue_payu_gateway_pay_twisto') {
        return 'Twisto - Zamów teraz i zapłać w ciągu 30 dni'
      } else if (code === 'vue_payu_gateway_pay_raty') {
        return 'PayU - Kup na raty'
      } else if (code === 'vue_payu_gateway') {
        return 'Szybki przelew internetowy'
      } else if (code === 'vue_payu_gateway_pay_card') {
        return 'Karta płatnicza'
      } else if (code === 'banktransfer') {
        return 'Przelew tradycyjny'
      } else {
        return code
      }
    },
    onAfterShippingDetails () {
      this.copyPersonalToBillingAddress()
      // this.isCODavaliable()
    },
    copyPersonalToBillingAddress () {
      this.payment.firstName = this.personalDetails.firstName
      this.payment.lastName = this.personalDetails.lastName
      this.payment.emailAddress = this.personalDetails.emailAddress
      this.payment.country = this.personalDetails.country
      this.payment.state = this.personalDetails.state
      this.payment.city = this.personalDetails.city
      this.payment.streetAddress = this.personalDetails.streetAddress
      this.payment.apartmentNumber = this.personalDetails.apartmentNumber
      this.payment.zipCode = this.personalDetails.zipCode
      this.payment.phoneNumber = this.personalDetails.phoneNumber
      this.payment.type = this.selectedCheckoutType
      if (!this.$store.state.customUseGenerateInvoice) {
        this.generateInvoice = false
        this.payment.company = ''
        this.payment.taxId = ''
        this.payment.regon = ''
      } else {
        // use generat invoice
        this.generateInvoice = true
        this.payment.company = this.$store.state.customCompany
        this.payment.taxId = this.$store.state.customTaxId
        this.payment.regon = this.$store.state.customRegon
      }
    },
    changeFilled () {
      this.$emit('PaymentUpdate', this.isFilled);
    }
  },
  validations () {
    if (!this.generateInvoice) {
      return {
        payment: {
          firstName: {
            required,
            minLength: minLength(3),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          country: {
            required
          },
          streetAddress: {
            required,
            maxLength: maxLength(60),
            unicodeAlphaNum
          },
          apartmentNumber: {
            maxLength: maxLength(10)
          },
          zipCode: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20),
            unicodeAlphaNum
          },
          city: {
            required
          },
          paymentMethod: {
            required
          }
        }
      }
    } else {
      return {
        payment: {
          firstName: {
            required,
            minLength: minLength(3),
            unicodeAlpha
          },
          lastName: {
            required,
            unicodeAlpha
          },
          company: {
            required,
            unicodeAlphaNum
          },
          customTaxId: {
            required,
            minLength: minLength(3)
          },
          country: {
            required
          },
          streetAddress: {
            required,
            maxLength: maxLength(60),
            unicodeAlphaNum
          },
          apartmentNumber: {
            maxLength: maxLength(10)
          },
          zipCode: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20),
            unicodeAlphaNum
          },
          city: {
            required,
            unicodeAlpha
          },
          paymentMethod: {
            required
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-gray {
  filter: contrast(0.3);
}
.hidden-elem {
  position: absolute;
  left: 9999px;
}
.mobile-footer-nav-but {
  width: 100%;
}
.step-title {
  @media (min-width: 768px) {
    display: flex;
  }
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-left: 27px;
}
@media (max-width: 767px) {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .p-0-20-xs {
    padding: 0 20px;
  }
  .mobile-footer-nav {
    position: fixed;
    background: rgb(255, 255, 255);
    width: 100%;
    padding: 10px 30px;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid rgb(206, 206, 206);
    z-index: 999;
    box-sizing: border-box;
  }
  .disable-but {
    background-color: #d9d9d9;
  }
  .m-xs-0 {
    margin: 0;
  }
  .pl-sm-20 {
    padding-left: 0px !important;
  }
  .mobile-label-header {
    margin-top:0;
    padding: 7px 0px 7px 16px;
    background-color: #041D59;
    color: #ffffff;
    text-transform: uppercase;
  }
  .p-0 {
    padding: 0;
  }
  .p-16-0 {
    padding: 0px 16px;
  }
}
.pl-sm-20 {
    padding-left: 0px;
}
.m-sm-0 {
  margin-left:0px;
  margin-right: 0px;
}

.disabled {
  opacity: 0.5;
}

.small-info {
  display: inline-block;
  line-height: 1em;
}
.method-label {
  line-height: normal;
}
</style>

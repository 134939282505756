<template>
  <div class="personal-details">
    <div class="row pl20 hidden-xs step-title mb15" :class="{ 'mb30' : isActive}">
      <div class="col-xs-1 col-sm-2 col-md-1 p0">
        <div
          class="number-circle lh35 brdr-circle align-center weight-700 brdr-1 brdr-cl-tertiary"
          :class="{ 'bg-cl-alternative brdr-cl-alternative active' : isActive,
                    'bg-cl-tertiary cl-tertiary' : !isFilled && !isActive,
                    'bg-cl-tertiary cl-tertiary' : isFilled && !isActive }"
        />
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row">
          <div
            class="col-xs-12 col-md-12 flex middle-sm between-sm pointer"
          >
            <p class="m0 h4 lh30 weight-700 uppercase">
              {{ $t('Personal Details') }}
            </p>
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-accent h5 flex edit-personal-detail" @click.prevent="edit">
                <span class="pr5">{{ $t('Edit personal details') }}</span>
                <img :src="'/assets/svg/create.svg'" class="filter-gray" width="26px" height="26px" alt="edit">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-sm-0" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-2" />
      <div class="col-xs-12 col-sm-9 col-md-10 p-0">
        <div class="row m-sm-0 between-sm">
          <div class="col-xs-12 p0 visible-xs" :class="{ 'p-0' : currentUser }">
            <div class="pills-buttons" v-if="!currentUser">
              <div
                class="pil"
                @click="variableTabs(1)"
                :class="{ 'active': isActivePill[1].show === true }"
              >
                {{ $t('Log in') }}
              </div>
              <div
                class="pil"
                @click="variableTabs(0)"
                :class="{ 'active': isActivePill[0].show === true }"
              >
                {{ $t('Personal Details') }}
              </div>
            </div>
            <h4 class="mobile-label-header" v-if="currentUser">
              {{ $t('Personal Details') }}
            </h4>
          </div>
        </div>
        <!-- document type selectors -->
        <div class="checkout-type__container p-0-12-xs mb25" v-if="isActivePill[0].show || currentUser">
          <p class="checkout-type__desc p0 m0 pb5">
            {{ $t('Zamawiam jako *') }}
          </p>
          <div class="col-xs-12 col-sm-10 col-md-11 p0 flex wrap">
            <div v-for="(type, index) in checkoutTypes" :key="index" class="ml10">
              <label class="radioStyled radio-checkout-type mb0"> {{ $t(type) }}
                <input
                  type="radio"
                  :value="type"
                  name="payment-method"
                  v-model="$store.state.ui.selectedCheckoutType"
                  @change="setCheckoutType(type)"
                >
                <span class="checkmark" />
              </label>
            </div>
          </div>
        </div>
        <!-- document type selectors END -->
        <div class="row p-0-12-xs m-sm-0 between-sm">
          <template v-if="isActivePill[0].show || currentUser">
            <base-input
              class="col-xs-12 col-md-6 mb35 fs-sm-14"
              type="text"
              :autofocus="true"
              name="first-name"
              :placeholder="$t('First name *')"
              v-model.trim="personalDetails.firstName"
              @blur="$v.personalDetails.firstName.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition: $v.personalDetails.firstName.$error && !$v.personalDetails.firstName.required && $v.personalDetails.firstName.minLength,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.firstName.minLength,
                  text: $t('Name must have at least 3 letters.')
                },
                {
                  condition: !$v.personalDetails.firstName.stringRegex && $v.personalDetails.firstName.minLength,
                  text: $t('First name can only contain letters.')
                }
              ]"
            />
            <base-input
              class="col-xs-12 col-md-6 mb35 fs-sm-14"
              type="text"
              name="last-name"
              :placeholder="$t('Last name *')"
              v-model.trim="personalDetails.lastName"
              @blur="$v.personalDetails.lastName.$touch()"
              autocomplete="fakeautocomplete"
              :validations="[{
                condition: $v.personalDetails.lastName.$error && !$v.personalDetails.lastName.required,
                text: $t('Field is required')
              },{
                condition: !$v.personalDetails.lastName.stringRegex,
                text: $t('Surname can only contain letters.')
              }]"
            />

            <!-- nip form -->
            <p v-show="isNipSelected && isNipDataResponse === false" class="col-xs-12 mt0 cl-primary-orange h5">
              {{ $t('Uzupełnij dane firmy.') }}
            </p>
            <base-input
              v-show="isNipSelected"
              class="col-xs-6 col-sm-6 col-md-6 mb35 p-0-20-xs"
              type="text"
              name="tax-id"
              @keyup.enter="isNipDataResponse === '' ? getNipDetails($store.state.customTaxId) : ''"
              :placeholder="$t('Tax identification number *')"
              v-model.trim="$store.state.customTaxId"
              autocomplete="tax-id"
              :disabled="isNipDataResponse !== ''"
              :validations="[
                {
                  condition: $v.customTaxId.$error,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.customTaxId.minLength || !$v.customTaxId.maxLength,
                  text: $t('Tax identification number must have 10 letters.')
                }
              ]"
            />
            <div class="col-xs-6 col-sm-6 col-md-6 mb10" v-show="isNipSelected && isNipDataResponse !== ''">
              <p v-show="isNipSelected && isNipDataResponse !== ''" class="get-nip-details pointer" @click="clearNipData()">
                {{ $t('Wyczyść dane') }}
              </p>
            </div>
            <base-input
              class="col-xs-12 col-md-12 mb35 fs-sm-14"
              type="text"
              name="company-name"
              :placeholder="$t('Company name *')"
              v-show="isNipSelected && isNipDataResponse !== ''"
              v-model.trim="$store.state.customCompany"
              autocomplete="company-name"
            />
            <div class="col-xs-6 col-sm-6 col-md-6 mb10" v-if="isNipSelected && isNipDataResponse === ''">
              <p
                id="get-nip-details"
                class="get-nip-details pointer"
                :class="{'disabled' : $v.customTaxId.$error || !$v.customTaxId.minLength || !$v.customTaxId.maxLength}"
                @click="$v.customTaxId.$error || !$v.customTaxId.minLength || !$v.customTaxId.maxLength ? '' : getNipDetails($store.state.customTaxId)"
              >
                {{ $t('Pobierz dane z GUS') }}
              </p>
            </div>
            <!-- nip form end -->
            <div class="col-xs-12 col-md-12 mb35 fs-sm-14 suggester-container" v-if="!isFormShown && !isNipSelected">
              <label
                class="validate-circle"
                :class="[(locitAddress === null || locitAddress.length === 0) ? 'not-valid' : 'cl-white bg-cl-error brdr-cl-error']"
              >
                <i class="h5 check center-flex-text">&#10003;</i>
              </label>
              <vue-autosuggest
                v-model="locitAddress"
                :suggestions="[{data:locitSuggestions}]"
                :input-props="{
                  id:'autosuggest__input', onInputChange: fetchResults,
                  autocomplete: 'off'
                }"
                @focus="focusSuggestions"
                @selected="fetchResults"
                @input="fetchResults"
                ref="addressRef"
                :get-suggestion-value="getSuggestionValue"
              >
                <template slot="after-input">
                  <label for="autosuggest__input">{{ $t('Wpisz adres w kolejności ulica -> numer ulicy -> miasto') }}</label>
                </template>
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">
                    {{ suggestion.item.city + ', ' + suggestion.item.prefix + ' ' + suggestion.item.street + ' ' + suggestion.item.building + ' ' + suggestion.item.zip }}
                    <small>({{ suggestion.item.voiv + ' ' + suggestion.item.pov + ' ' + suggestion.item.mun }})</small>
                  </span>
                </template>
                <template slot="after-suggestions">
                  <p class="pl5 pointer">
                    {{ $t("Don't see your address? Click here and enter the address manually") }}
                  </p>
                </template>
              </vue-autosuggest>
            </div>
            <div class="col-xs-12 mb25 mt-25" v-if="locitSuggestions && !isFormShown">
              <p class="pl5 pointer m0" @click="showForm()">
                {{ $t("Don't see your address?") }}  <span class="cl-yellow">{{ $t("Click here and enter the address manually") }}</span>
              </p>
            </div>
            <template v-if="isFormShown || (isNipSelected && isNipDataResponse !== '')">
              <base-input
                class="col-xs-12 col-md-12 mb35 fs-sm-14"
                type="text"
                name="street-address"
                :placeholder="$t('Street name *')"
                v-model.trim="personalDetails.streetAddress"
                @blur="$v.personalDetails.streetAddress.$touch()"
                autocomplete="fakeautocomplete"
                :validations="[
                  {
                    condition: $v.personalDetails.streetAddress.$error && !$v.personalDetails.streetAddress.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.personalDetails.streetAddress.maxLength,
                    text: $t('Value is too long')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 col-md-6 mb35 fs-sm-14"
                type="text"
                name="apartment-number"
                :placeholder="$t('House/Apartment number *')"
                v-model.trim="personalDetails.apartmentNumber"
                @blur="$v.personalDetails.apartmentNumber.$touch()"
                autocomplete="fakeautocomplete"
                :validations="[
                  {
                    condition: $v.personalDetails.apartmentNumber.$error && !$v.personalDetails.apartmentNumber.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.personalDetails.apartmentNumber.maxLength,
                    text: $t('Value is too long')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 col-md-6 mb35 fs-sm-14"
                type="text"
                name="flat-number"
                :placeholder="$t('Flat number')"
                v-model.trim="personalDetails.flatNumber"
                autocomplete="fakeautocomplete"
              />

              <base-input
                class="col-xs-12 col-md-6 mb35 fs-sm-14"
                type="text"
                name="city"
                :placeholder="$t('City *')"
                v-model.trim="personalDetails.city"
                @blur="$v.personalDetails.city.$touch()"
                autocomplete="fakeautocomplete"
                :validations="[
                  {
                    condition: $v.personalDetails.city.$error && !$v.personalDetails.city.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.personalDetails.city.maxLength,
                    text: $t('Value is too long')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 col-md-6 mb35 fs-sm-14"
                type="text"
                name="zip-code"
                :placeholder="$t('Zip-code *')"
                v-model.trim="personalDetails.zipCode"
                @blur="$v.personalDetails.zipCode.$touch()"
                autocomplete="fakeautocomplete"
                :validations="[
                  {
                    condition: $v.personalDetails.zipCode.$error && !$v.personalDetails.zipCode.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.personalDetails.zipCode.minLength,
                    text: $t('Zip-code must contain at least 6 characters.')
                  },
                  {
                    condition: $v.personalDetails.zipCode.minLength && !$v.personalDetails.zipCode.postCodeRegex,
                    text:$t('Enter the zip code in the xx-xxx format.')
                  },
                  {
                    condition: !$v.personalDetails.zipCode.maxLength,
                    text: $t('Value is too long')
                  }
                ]"
              />
            </template>
            <base-input
              class="col-xs-12 col-md-12 mb35 fs-sm-14"
              type="text"
              name="phone-number"
              :placeholder="$t('Phone Number') + ' *'"
              @blur="$v.personalDetails.phoneNumber.$touch()"
              v-model.trim="personalDetails.phoneNumber"
              autocomplete="fakeautocomplete"
              :validations="[
                {
                  condition: $v.personalDetails.phoneNumber.$error && !$v.personalDetails.phoneNumber.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.phoneNumber.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />
            <base-input
              class="col-xs-12 mb25 fs-sm-14"
              type="email"
              name="email-address"
              :placeholder="$t('Email address *')"
              v-model="personalDetails.emailAddress"
              @blur="$v.personalDetails.emailAddress.$touch()"
              autocomplete="fakeautocomplete"
              @keyup.enter="online ? validateData(personalDetails.emailAddress) : ''"
              :validations="[
                {
                  condition: $v.personalDetails.emailAddress.$error && !$v.personalDetails.emailAddress.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.emailAddress.email && $v.personalDetails.emailAddress.$error,
                  text: $t('Please provide valid e-mail address.')
                },
                {
                  condition: !$v.personalDetails.emailAddress.maxLength,
                  text: $t('Value is too long')
                }
              ]"
            />

            <base-checkbox
              v-if="!currentUser"
              class="col-xs-12 mb15 fs-sm-14 createAccountCheckbox"
              id="createAccountCheckbox"
              v-model="createAccount"
            >
              {{ $t('I want to create an account') }}
            </base-checkbox>
            <template v-if="createAccount && !currentUser">
              <base-input
                class="col-xs-12 mb35 fs-sm-14"
                type="password"
                name="password"
                ref="password"
                :placeholder="$t('Password *')"
                v-model="password"
                @blur="$v.password.$touch()"
                autocomplete="fakeautocomplete"
                :validations="[
                  {
                    condition: $v.password.$error && !$v.password.required,
                    text: $t('Field is required.')
                  },
                  {
                    condition: !$v.password.minLength && $v.password.$error,
                    text: $t('Password must have at least 6 letters.')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 mb35 fs-sm-14"
                type="password"
                name="password-confirm"
                :placeholder="$t('Repeat password *')"
                @blur="$v.rPassword.$touch()"
                v-model="rPassword"
                autocomplete="fakeautocomplete"
                :validations="[
                  {
                    condition: $v.rPassword.$error && !$v.rPassword.required,
                    text: $t('Field is required.')
                  },
                  {
                    condition:!$v.rPassword.sameAsPassword && $v.rPassword.required,
                    text: $t('Passwords must be identical.')
                  }
                ]"
              />
            </template>
          </template>
          <template v-if="isActivePill[1].show && !currentUser">
            <login v-if="activeElem !== 'forgot-pass'" />
            <forgot-pass v-if="activeElem === 'forgot-pass'" />
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="row m-sm-0" v-if="isFilled && !isActive">
      <div class="col-sm-offset-2 col-sm-10">
        <p class="shipping-address-label ff-light">
          {{ this.$store.state.checkout.personalDetails.firstName }} {{ this.$store.state.checkout.personalDetails.lastName }}
        </p>
        <p class="shipping-address-label ff-light">
          {{ this.$store.state.checkout.personalDetails.streetAddress }} {{ this.$store.state.checkout.personalDetails.apartmentNumber }}
        </p>
        <p class="shipping-address-label ff-light">
          {{ this.$store.state.checkout.personalDetails.zipCode }}, {{ this.$store.state.checkout.personalDetails.city }}
        </p>
        <p class="shipping-address-label ff-light">
          {{ this.$store.state.checkout.personalDetails.phoneNumber }}
        </p>
      </div>
    </div> -->
    <div class="row mobile-footer-nav" v-show="isActive">
      <div class="hidden-xs col-sm-2 col-md-2" />
      <div class="col-xs-12 col-sm-9 col-md-10 save-bottom" @click="changeFilled(true)">
        <div class="row my20 m-xs-0">
          <div class="col-xs-12 col-md-8 button-container">
            <button-full
              data-testid="personalDetailsSubmit"
              @click.native="online ? validateData(personalDetails.emailAddress) : ''"
              :disabled="createAccount ? $v.$invalid : $v.personalDetails.$invalid"
              class="mobile-footer-nav-but"
            >
              {{ $t('Continue to shipping') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import { PersonalDetails } from '@vue-storefront/core/modules/checkout/components/PersonalDetails';
import rootStore from '@vue-storefront/core/store';
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox';
import BaseInput from 'theme/components/core/blocks/Form/BaseInput';
import ButtonFull from 'theme/components/theme/ButtonFull';
import Login from 'theme/components/core/blocks/Auth/Login';
import Vue from 'vue';
import VueResource from 'vue-resource';
import { VueAutosuggest } from 'vue-autosuggest';
import ForgotPass from 'theme/components/core/blocks/Auth/ForgotPass'

Vue.use(VueResource);

const stringRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  // special characters validation
  return (/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).test(value)
}

const postCodeRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /\d{2}-\d{3}/g.test(value)
}

export default {
  components: {
    ForgotPass,
    ButtonFull,
    BaseCheckbox,
    BaseInput,
    Login,
    VueAutosuggest
  },
  data () {
    return {
      isActivePill: [{ show: true }, { show: false }],
      validationCalls: 0,
      locitSuggestions: null,
      suggesterActive: false,
      locitAddress: null,
      isFormShown: false
    };
  },
  props: {
    online: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    // TO DO: repair update case when user log in while in checkout
    currentUser: function (newVal, oldVal) {
      if (newVal !== null) {
        this.$bus.$on('user-after-loggedin', this.onLoggedIn(newVal))
        this.onLoggedIn(newVal)
        this.changeFilled()
      }
    }
  },
  beforeMount () {
    if (this.currentUser) {
      this.onLoggedIn(this.currentUser)
    }
    this.$bus.$on('checkout-after-load', this.showFormIfFilled)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.showFormIfFilled)
  },
  mounted () {
    this.changeFilled();
    this.personalDetails.country = 'PL';
    if (this.locitAddress) {
      this.focusSuggestions()
    }
    // else {
    //   this.getCurrentLocalisation();
    // }
  },
  created () {
    this.personalDetails.country = 'PL';
  },
  methods: {
    isDisabled () {
      if (this.$store.state.customUseGenerateInvoice) {
        return this.createAccount
          ? this.$v.$invalid || !this.customTaxId || !this.customCompany
          : this.$v.personalDetails.$invalid || !this.customTaxId || !this.customCompany
      } else {
        return this.createAccount
          ? this.$v.$invalid
          : this.$v.personalDetails.$invalid
      }
    },
    setAddManually () {
      this.isFormShown = true
      this.$store.dispatch('ui/changeNipDataResponse', false)
    },
    clearNipData () {
      this.$store.dispatch('ui/changeNipDataResponse', '')
      this.$store.state.checkout.personalDetails.city = ''
      this.$store.state.checkout.personalDetails.streetAddress = ''
      this.$store.state.checkout.personalDetails.zipCode = ''
    },
    getNipDetails (nip) {
      return this.$http
        .get(
          this.urlNipDetails,
          {
            params: {
              nip: nip
            }
          }
        ).then(response => {
          if (response.bodyText) {
            this.$store.dispatch('ui/changeNipDataResponse', true)
            const result = JSON.parse(response.bodyText).response
            this.$store.state.customTaxId = result.nip
            this.$store.state.customRegon = result.regon
            this.$store.state.customCompany = result.name
            this.$store.state.checkout.personalDetails.city = result.address.city
            this.$store.state.checkout.personalDetails.streetAddress = result.address.street
            this.$store.state.checkout.personalDetails.zipCode = result.address.zip_code
            this.$store.state.checkout.paymentDetails.company = result.name
            this.$store.state.checkout.paymentDetails.customTaxId = result.nip
            this.$store.state.checkout.paymentDetails.customRegon = result.regon
            this.$store.state.checkout.paymentDetails.type = this.selectedCheckoutType
            this.$store.state.checkout.paymentDetails.city = result.address.city
            this.$store.state.checkout.paymentDetails.streetAddress = result.address.street
            this.$store.state.checkout.paymentDetails.zipCode = result.address.zip_code
          } else {
            this.$store.dispatch('ui/changeNipDataResponse', false)
            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: this.$t('Brak firmy o podanym NIP w bazie GUS'),
              action1: { label: 'OK' }
            })
          }
        }).catch(error => {
          if (error.status === 400 || error.status === 404) {
            this.$store.dispatch('ui/changeNipDataResponse', false)
            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: this.$t('Brak firmy o podanym NIP w bazie GUS'),
              action1: { label: 'OK' }
            })
          } else {
            if (error) {
              this.$store.dispatch('ui/changeNipDataResponse', false)
              this.$store.dispatch('notification/spawnNotification', {
                type: 'error',
                message: this.$t('Baza GUS jest chwilowo niedostępna, proszę wprowadzić dane ręcznie'),
                action1: { label: 'OK' }
              })
            }
          }
        });
    },
    setCheckoutType (data) {
      this.$store.dispatch('ui/changeCheckoutType', data).then(res => {
        this.isNipSelected
          ? this.$store.state.customUseGenerateInvoice = true
          : this.$store.state.customUseGenerateInvoice = false
      })
    },
    showFormIfFilled () {
      if (this.personalDetails.streetAddress) {
        this.showForm()
      }
    },
    onLoggedIn (receivedData) {
      const userBasic = {
        firstName: receivedData.firstname,
        lastName: receivedData.lastname,
        emailAddress: receivedData.email
      }
      let userWithAdress = receivedData.addresses[0] ? {
        streetAddress: receivedData.addresses[0].street[0],
        apartmentNumber: receivedData.addresses[0].street[1],
        city: receivedData.addresses[0].city,
        zipCode: receivedData.addresses[0].postcode,
        country: 'PL',
        phoneNumber: receivedData.addresses[0].telephone
      } : {
        streetAddress: null,
        apartmentNumber: null,
        city: null,
        zipCode: null,
        country: null,
        phoneNumber: ''
      }
      if (receivedData.addresses[0]) {
        this.showForm(true)
      }
      this.personalDetails = {
        ...userBasic, ...userWithAdress
      }
    },
    variableTabs (z) {
      for (let i = 0; i < 2; i++) {
        this.isActivePill[i].show = false;
      }
      this.isActivePill[z].show = true;
    },
    changeFilled () {
      this.$emit('personalDetailsUpdate', this.isFilled);
    },
    validateData (email) {
      this.validateMailbox(email).then(res => {
        if (!res) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t(
              'Zmieniłeś adres email zbyt wiele razy. Spróbuj ponownie za 5 minut.'
            ),
            action1: { label: this.$t('OK') }
          });
          if (this.validationCalls === 4) {
            setTimeout(() => {
              this.validationCalls = 0;
            }, 300000);
          }
          return false;
        } else if (!res.valid) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Podany adres email nie istnieje!'),
            action1: { label: this.$t('OK') }
          });
          return false;
        } else if (res.is_disposable) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t(
              'Nie możesz zapisać się do newslettera korzystając z tymczasowego adresu email!'
            ),
            action1: { label: this.$t('OK') }
          });
          return false;
        } else if (res.mailbox_verification === 'false') {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Podany adres email jest nieprawidłowy!'),
            action1: { label: this.$t('OK') }
          });
          return false;
        } else {
          this.sendDataToCheckout();
          return true;
        }
      });
    },
    validateMailbox (value) {
      this.validationCalls++;
      if (this.validationCalls >= 4) {
        return Promise.resolve(false);
      }
      return this.$http
        .get('https://api.mailgun.net/v3/address/validate', {
          params: {
            address: value,
            api_key: rootStore.state.config.apiKeys.mailgun,
            mailbox_verification: 'true'
          }
        })
        .then(response => {
          return {
            valid: response.data.is_valid,
            is_disposable: response.data.is_disposable_address,
            mailbox_verification: response.data.mailbox_verification
          };
        });
    },
    showForm () {
      this.isFormShown = true
      this.unfocusSuggestions()
    },
    fetchResults (address) {
      if (address) {
        if (typeof address === 'string') {
          this.locitAddress = address;
        } else if (address) {
          this.showForm()
          this.updatePersonalDetails(
            address.item.prefix,
            address.item.street,
            address.item.building,
            address.item.city,
            address.item.zip,
            'PL',
            address.item.voiv
          );
          this.locitAddress =
            address.item.city +
            ' ' +
            address.item.prefix +
            ' ' +
            address.item.street +
            ' ' +
            address.item.building;
        }
      } else if (address === null) {
        this.showForm()
      }

      // removing flat number from query
      let query = this.locitAddress;
      const prefixes = ['/', 'm.'];

      if (query) {
        for (let p in prefixes) {
          if (query.indexOf(prefixes[p]) > 0) {
            const a = query.split(' ');
            let found = a.find(el => el.indexOf(prefixes[p]) > -1);
            let i = a.indexOf(found);
            if (found.length === prefixes[p].length) {
              this.personalDetails.flatNumber = a[i + 1];
              a.splice(i, 2);
            } else {
              found = found.split(prefixes[p]);
              this.personalDetails.flatNumber = found[1];
              a[i] = found[0];
            }
            query = a.join(' ');
          }
        }

        if (query.length > 3) {
          return this.$http
            .get(
              'https://api.locit.dev.beecommerce.pl/autocomplete/full/' +
              query,
              {
                params: {
                  format: 'json',
                  charset: 'UTF-8',
                  key: rootStore.state.config.apiKeys.locit
                }
              }
            )
            .then(response => {
              const locit = JSON.parse(response.bodyText);
              this.locitSuggestions = locit.data;
            })
            .catch(error => {
              this.showForm()
              console.error(error)
            });
        }
      }
    },
    focusSuggestions () {
      this.suggesterActive = true;
    },
    unfocusSuggestions () {
      this.suggesterActive = false;
    },
    getSuggestionValue (suggestion) {
      const s = suggestion.item;
      return s.city + ', ' + s.prefix + ' ' + s.street + ' ' + s.building;
    },
    updateLocitAddress (city, prefix, street, apartment) {
      this.locitAddress = city
        ? city + (prefix ? ' ' + prefix.toLowerCase() : '') + ' ' + street + ' ' + apartment
        : null;
      this.$refs.addressRef.searchInput = this.locitAddress;
      this.focusSuggestions();
    },
    updatePersonalDetails (prefix, street, building, city, zip, country, voiv) {
      this.personalDetails.streetAddress = prefix + ' ' + street;
      this.personalDetails.apartmentNumber = building;
      this.personalDetails.city = city;
      this.personalDetails.zipCode = zip;
      this.personalDetails.country = country;
      this.personalDetails.state = voiv;
    },
    getCurrentLocalisation () {
      if (this.personalDetails.city) {
        this.updateLocitAddress(
          this.personalDetails.city,
          '',
          this.personalDetails.streetAddress,
          this.personalDetails.apartmentNumber
        );
      } else {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            position => {
              if (!this.locitAddress) {
                return this.$http
                  .get(
                    'https://api.locit.dev.beecommerce.pl/geocoding_reversed/' +
                    position.coords.latitude +
                    '/' +
                    position.coords.longitude,
                    {
                      params: {
                        format: 'json',
                        charset: 'UTF-8',
                        key: rootStore.state.config.apiKeys.locit
                      }
                    }
                  )
                  .then(response => {
                    const locit = JSON.parse(response.bodyText);
                    const geo = locit.data.listing[0];
                    if (geo) {
                      this.updatePersonalDetails(
                        geo.gus_prefix,
                        geo.street,
                        geo.building,
                        geo.city,
                        geo.zip,
                        'PL',
                        geo.voiv
                      );
                      this.updateLocitAddress(
                        geo.city,
                        geo.gus_prefix,
                        geo.street,
                        geo.building
                      );
                    } else {
                      this.updateLocitAddress(
                        this.personalDetails.city,
                        '',
                        this.personalDetails.streetAddress,
                        this.personalDetails.apartmentNumber
                      );
                    }
                  })
                  .catch(error => error);
              }
            },
            err => err
          );
        }
      }
    }
  },
  mixins: [PersonalDetails],
  validations: {
    personalDetails: {
      firstName: {
        required,
        minLength: minLength(3),
        stringRegex
      },
      lastName: {
        required,
        minLength: minLength(3),
        stringRegex
      },
      emailAddress: {
        required,
        email,
        maxLength: maxLength(200)
      },
      streetAddress: {
        required,
        maxLength: maxLength(100)
      },
      apartmentNumber: {
        required,
        maxLength: maxLength(10)
      },
      city: {
        required,
        maxLength: maxLength(100)
      },
      zipCode: {
        required,
        minLength: minLength(5),
        postCodeRegex,
        maxLength: maxLength(20)
      },
      country: {
        required
      },
      phoneNumber: {
        required,
        maxLength: maxLength(22)
      }
    },
    customTaxId: {
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    // locitAddress: {
    //   required,
    //   minLength: minLength(3)
    // },
    password: {
      required,
      minLength: minLength(6)
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    countryOptions () {
      return this.countries.map(item => {
        return {
          value: item.code,
          label: item.name
        };
      });
    },
    ...mapState({
      activeElem: state => state.ui.authElem,
      currentUser: state => state.user.current,
      paymentDetails: state => state.checkout.paymentDetails,
      checkoutTypes: state => state.ui.checkoutTypes,
      selectedCheckoutType: state => state.ui.selectedCheckoutType,
      isNipDataResponse: state => state.ui.isNipDataResponse
    }),
    urlNipDetails () {
      return rootStore.state.config.apiKeys.urlNipDetails
    },
    customTaxId () {
      return rootStore.state.customTaxId
    },
    isNipSelected () {
      return this.$store.state.ui.selectedCheckoutType === 'Firma'
    }
  }
};
</script>

<style lang="scss" scoped>
.get-nip-details {
  @media (max-width: 767px) {
    font-size: 14px;
    padding: 10px;
  }
  color: #ff8100;
  padding: 8px;
  border-radius: 25px;
  margin: 0;
  border: 1px solid #ff8100;
  text-align: center;
  background-color: #fff;
}
.checkout-type__container {
  @media (max-width: 767px) {
    position: relative;
    border: 1px solid #d3d3d3;
    border-radius: 25px;
    padding: 5px 5px !important;
    margin-left: 20px;
    margin-right: 20px;
    p {
      position: absolute;
      top: -10px;
      left: 20px;
      font-size: 12px;
      background-color: #fff;
      padding-bottom: 0;
    }
    .radio-checkout-type {
      margin-bottom: 0 !important;
      font-size: 14px !important;
    }
  }
}
.check {
  width: 16px;
  height: 16px;
}
.filter-gray {
  filter: contrast(0.3);
}
.mobile-footer-nav-but {
  width: 100%;
}
.step-title {
  @media (min-width: 768px) {
    display: flex;
  }
  align-items: center;
  // margin-bottom: 15px;
  padding-left: 27px;
}
.pl-sm-20 {
  padding-left: 20px;
}
.link {
  text-decoration: underline;
}

.login-prompt {
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
}
.disable-but {
  background-color: #d9d9d9;
}
@media (max-width: 767px) {
  .between-sm {
    justify-content: space-between;
  }
  .col-xs-6 {
    flex-basis: 48%;
    max-width: 48%;
  }
  .m-sm-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .pl-sm-20 {
    padding-left: 0px;
  }
  .mobile-footer-nav {
    margin: 0;
    position: fixed;
    background: white;
    width: 100%;
    padding: 10px 30px;
    font-size: 1rem;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid #cecece;
    z-index: 999;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .m-xs-0 {
    margin: 0;
  }
  .fs-sm-14 {
    font-size: 14px;
  }
  .mobile-label-header {
    width: 85%;
    margin: 0px auto 28px;
    padding: 8px 0;
    color: white;
    font-size: 14px;
    background-color: #ff4200;
    text-align: center;
  }
  .p-0 {
    padding: 0;
  }
  .p-0-12-xs {
    padding: 0 12px;
  }
}
.pills-buttons {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0px 0px 25px 0px;
}
.pills-buttons {
  .pil {
    width: 50%;
    padding: 5px 0;
    color: #191919;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    &.active::after {
      background-color: #041D59;
    }
    &::after {
      content: "";
      width: 100%;
      height: 5px;
      display: block;
      background-color: #d3d3d3;
      position: absolute;
      bottom: -4px;
      z-index: -1;
    }
  }
}
#autosuggest {
  width: 100%;
  position: relative;
}

#autosuggest {
  /deep/ label {
    @media (max-width: 767px) {
      font-size: 12px;
    }
    color: #999;
    position: absolute;
    pointer-events: none;
    user-select: none;
    left: 10px;
    top: -20px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    font-size: 14px;
  }
  /deep/ #autosuggest__input {
    box-sizing: border-box;
    -webkit-appearance: none;
    background: inherit;
    border: none;
    border: 1px solid #d3d3d3;
    border-radius: 20px;
    padding: 10px 0 10px 10px;
    font-size: 16px;
    width: 100%;
    font-family: "protipo",sans-serif;
  }
  /deep/ #autosuggest__input:placeholder-shown  {
    font-size: 14px;
    color: #999 !important;
    padding-left: 10px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 25px;
  }
  /deep/ #autosuggest__input:focus,
  /deep/ #autosuggest__input:hover {
    outline: none;
    border-color: #999;
  }

  /deep/ .autosuggest__results-container {
    position: relative;
    width: 100%;
    background-color: var(--theme-bg);
  }
  /deep/ .autosuggest__results {
    background-color: #fff;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 0;
    overflow: scroll;
    max-height: 400px;
  }
  /deep/ .autosuggest__results li:hover {
    background: #eee;
  }
  /deep/ .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  /deep/ .autosuggest__results li {
    cursor: pointer;
    padding: 8px;
  }
}
.suggester-label {
  color: #999;
  position: absolute;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 8px;
  top: 10px;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

// /deep/ .country-select label {
//   left: 8px;
// }
.suggester-label.active {
  top: -10px;
  font-size: 14px;
  color: #4dba87;
}
.suggester-container {
  position: relative;
  // min-height: 4.5rem;
}
.autosuggest__results small {
  display: block;
  color: #9e9e9e;
  font-size: 0.8em;
}
.validate-circle {
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: 18px;
  top: 8px;
  left: auto;
  display: flex;
  padding: 2px;
  border: 1px solid;
  border-radius: 30px;
}
.not-valid {
  border-color: #d3d3d3;
  color: #d3d3d3;
}
.icon {
  right: 6px;
  top: 10px;
}
.label-active {
  position: absolute;
  top: -10px;
  font-size:14px;
  color:#ff5100;
}
.min-height {
  min-height: 4.5rem;
}
.mt-25 {
  margin-top: -25px;
}
</style>

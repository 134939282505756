import * as types from './store/mutation-types'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { getGuaClientId } from 'theme/utils/getGuaClientId'
import { getGuaUa } from 'theme/utils/getGuaUa'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

import config from 'config'

const PaymentPayUStore = {
  namespaced: true,
  state: {
    methods: null,
    additional_payment_data: {
      payu_method: '',
      payu_method_type: 'PBL',
      gua_client_id: '',
      parcel_locker_no: ''
    }
  },
  mutations: {
    [types.SET_BACKEND_PAYMENT_METHODS] (state, paymentMethods) {
      state.methods = paymentMethods
    },
    [types.SET_GUA_CLIENT_ID] (state, gua_client_id) {
      state.additional_payment_data.gua_client_id = gua_client_id
    },
    [types.SET_GUA_UA] (state, gua_user_agent) {
      if (gua_user_agent) { state.additional_payment_data.gua_ua = gua_user_agent }
    },
    [types.SET_PARCEL_LOCKER_NO] (state, parcel_locker_no) {
      state.additional_payment_data.parcel_locker_no = parcel_locker_no
    },
    [types.SET_PAYU_METHOD] (state, payu_method) {
      state.additional_payment_data.payu_method = payu_method
    }
  },
  getters: {
    getAdditionalPaymentData: state => state.additional_payment_data
  }
}

export const KEY = 'payment-payu'

export const PaymentPayUModule: StorefrontModule = function ({ store }) {
  store.registerModule('payment-payu', PaymentPayUStore)

  let correctPaymentMethod = false

  const afterPlaceOrder = (payload) => {
    if (correctPaymentMethod) {
      console.log('PaymentPayUModule correctPaymentMethod ' + correctPaymentMethod)
      fetch((config.payment_payu.endpoint.payment_information).replace('{{ backendOrderId }}',
        payload.confirmation.backendOrderId), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }).then(response => response.json())
        .then(data => {
          if (data.result && data.result[0].redirectUri) {
            window.location.href = data.result[0].redirectUri
          }
        })
    }
  }

  const placeOrder = () => {
    if (correctPaymentMethod) {
      // console.log(true)
      let shippingDetails = store.getters['checkout/getShippingDetails'];
      store.commit(KEY + '/' + types.SET_PARCEL_LOCKER_NO, shippingDetails.pickupId)

      let additionalPaymentData = store.getters[KEY + '/' + 'getAdditionalPaymentData']

      let storeView = currentStoreView()

      additionalPaymentData['base_return_url'] = config.payment_payu.base_return_url[storeView.storeId]

      EventBus.$off('order-after-placed')
      EventBus.$bus.$on('order-after-placed', afterPlaceOrder)

      console.log('PlaceOrder in PaymentPayUModule')
      EventBus.$emit('checkout-do-placeOrder', additionalPaymentData)
    }
  }

  if (!isServer) {
    EventBus.$on('set-unique-payment-methods', methods => {
      store.commit('payment-backend-methods/' + types.SET_BACKEND_PAYMENT_METHODS, methods)
    })

    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      let methods = store.state['payment-backend-methods'].methods
      if ((paymentMethodCode === 'vue_payu_gateway' || paymentMethodCode === 'vue_payu_gateway_pay_card' || paymentMethodCode === 'vue_payu_gateway_pay_twisto' || paymentMethodCode === 'vue_payu_gateway_pay_raty') && methods !== null && methods.find(item => (item.code === paymentMethodCode && item.is_server_method === true))) {
        correctPaymentMethod = true

        EventBus.$off('checkout-before-placeOrder')
        EventBus.$on('checkout-before-placeOrder', placeOrder)
        let payMethod
        if (paymentMethodCode === 'vue_payu_gateway_pay_card') {
          payMethod = 'c'
        } else if (paymentMethodCode === 'vue_payu_gateway_pay_twisto') {
          payMethod = 'dpt'
        } else if (paymentMethodCode === 'vue_payu_gateway_pay_raty') {
          payMethod = 'ai'
        } else {
          payMethod = ''
        }
        store.commit(KEY + '/' + types.SET_PAYU_METHOD, payMethod)
        store.commit(KEY + '/' + types.SET_GUA_CLIENT_ID, getGuaClientId())
        store.commit(KEY + '/' + types.SET_GUA_UA, getGuaUa())
      } else {
        correctPaymentMethod = false
      }
    })
  }
}

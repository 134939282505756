<template>
  <div class="row between-xs" :class="{ 'p25': !isCheckoutPage }">
    <template v-if="!isCheckoutPage">
      <div class="blend">
        <product-image :image="image" />
      </div>
      <div class="col-xs">
        <div class="row">
          <div class="col-xs-12 col-md-8 pb15">
            <div class="mb15">
              <div class="h4 cl-black">
                {{ product.name | htmlDecode }}
              </div>
              <div class="error" v-if="product.errors && Object.keys(product.errors).length > 0">
                {{ product.errors | formatProductMessages }}
              </div>
              <div class="h5 cl-accent pt5">
                {{ product.sku }}
              </div>
              <div class="h6 cl-accent pt5 options" v-if="product.totals && product.totals.options">
                <div v-for="opt in product.totals.options" :key="opt.label">
                  <span class="opn">{{ opt.label }}: </span>
                  <span class="opv" v-html="opt.value" />
                </div>
              </div>
              <div class="h6 cl-accent pt5 options" v-else-if="product.options">
                <div v-for="opt in product.options" :key="opt.label">
                  <span class="opn">{{ opt.label }}: </span>
                  <span class="opv" v-html="opt.value" />
                </div>
              </div>
            </div>
            <!-- <div>
              <div>
                <span class="h5 cl-secondary">
                  {{ $t('Qty') }}
                  <span class="weight-700">
                    {{ product.qty }}
                  </span>
                </span>
              </div>
            </div> -->
          </div>
          <div class="col-xs-12 col-md-1">
            {{ product.qty }}
          </div>
          <div class="col-xs-12 col-md-3 ">
            <div v-if="!product.totals">
              <span class="h4 cl-error" v-if="product.special_price">{{ product.qty }} x {{ product.price_incl_tax | price }}</span>
              <span class="price-original h5" v-if="product.special_price">{{ product.qty }} x {{ product.original_price_incl_tax | price }}</span>
              <span v-if="!product.special_price" class="h4">{{ product.qty }} x {{ product.price_incl_tax | price }}</span>
            </div>
            <div v-if="product.totals">
              <span class="h4 cl-error" v-if="product.totals.discount_amount">{{ product.qty }} x {{ (product.totals.row_total_incl_tax - product.totals.discount_amount) / product.qty | price }} </span>
              <span class="price-original h5" v-if="product.totals.discount_amount">{{ product.qty }} x {{ product.price_incl_tax | price }}</span>
              <span v-if="!product.totals.discount_amount" class="h4">{{ product.qty }} x {{ product.price_incl_tax | price }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="col-xs">
        <div class="row my5 product-list">
          <div class="col-xs-3 col-md-3">
            <div class="ml10 bg-cl-secondary">
              <product-image :image="image" />
            </div>
          </div>
          <div class="col-xs-9 col-md-5">
            <div class="mb15 desktop-product">
              <div class="h5 weight-600 cl-black mobile-product-name ff-semi">
                {{ product.name | htmlDecode }}
              </div>
              <div class="error ff-light" v-if="product.errors && Object.keys(product.errors).length > 0">
                {{ product.errors | formatProductMessages }}
              </div>
              <!-- <div class="h5 cl-accent pt5">{{ product.sku }}</div> -->
              <div class="h6 mt10 cl-accent options" v-if="product.totals && product.totals.options">
                <div class="" v-for="opt in product.totals.options" :key="opt.label">
                  <span class="opn ff-semi">{{ $t(opt.label) }}: </span>
                  <span class="opv ff-light" v-if="opt.label === 'Size'">{{ sizeBeautifier(opt.value) }}</span>
                  <span class="opv ff-light" v-else v-html="opt.value" />
                </div>
              </div>
              <div class="h6 mt10 cl-accent options" v-else-if="product.options">
                <div class="" v-for="opt in product.options" :key="opt.label">
                  <span class="opn ff-semi">{{ $t(opt.label) }}: </span>
                  <span class="opv ff-light" v-if="opt.label === 'Size'">{{ sizeBeautifier(opt.value) }}</span>
                  <span class="opv ff-light" v-else v-html="opt.value" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-2 col-md-1 center-sm">
            <div class="brdr-1 brdr-cl-primary flex h4 center-xs w-100">
              {{ product.qty }}
            </div>
          </div>
          <div class="col-xs-10 col-md-3 ">
            <div v-if="!product.totals">
              <span class="cl-error " v-if="product.special_price">{{ product.qty * product.price_incl_tax | price }} </span>
              <span class="price-original cl-accent h5" v-if="product.special_price">{{ product.qty * product.original_price_incl_tax | price }}</span>
              <span v-if="!product.special_price" class="h4">{{ product.qty * product.price_incl_tax | price }}</span>
            </div>
            <div v-if="product.totals">
              <span class="cl-error" v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax - product.totals.discount_amount | price }} </span>
              <span class="price-original h5 cl-black" v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax | price }}</span>
              <span v-if="!product.totals.discount_amount" class="h4">{{ product.totals.row_total_incl_tax | price }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProductImage from 'theme/components/core/ProductImage'
import CurrentPage from 'theme/mixins/currentPage'
import { Product } from '@vue-storefront/core/modules/checkout/components/Product'
import { onlineHelper } from '@vue-storefront/core/helpers'

export default {
  components: {
    ProductImage
  },
  mixins: [Product, CurrentPage],
  computed: {
    isOnline () {
      return onlineHelper.isOnline
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    }
  },
  methods: {
    sizeBeautifier (size) {
      if (size === 'X') {
        return 'ONE SIZE'
      }
      let newLabel = size.split('.')
      let whole = newLabel[0]
      if (newLabel[1]) {
        let fraction = newLabel[1]
        if (fraction === '33') {
          return whole + ' ' + '1/3'
        } else if (fraction === '66') {
          return whole + ' ' + '2/3'
        } else {
          return size
        }
      }
      return size
    }
  }
}
</script>

<style scoped>
.price-original {
  text-decoration: line-through;
}
.blend {
  mix-blend-mode: multiply;
}
@media (max-width: 767px) {
  .mobile-product-name {
    /* font-size: 14px; */
  }
  .text-right {
    text-align: right;
  }
}
@media (min-width: 768px) {
 .product-list {
   align-items: center;
 }
 .desktop-product {
   margin-top: 15px;
 }
 .mobile-product-name {
   font-size: 16px;
 }
 .desktop-product span{
   font-size: 14px;
 }
 .text-right {
   font-size: 20px;
   text-align:right;
       display: flex;
    flex-direction: column;
 }
}

</style>

<template>
  <div class="personal-details">
    <div class="row pl20 hidden-xs step-title mb15" :class="{ 'mb30' : isActive}">
      <div class="col-xs-1 col-sm-2 col-md-1 p0">
        <div
          class="number-circle lh35 brdr-circle align-center weight-700 brdr-1 brdr-cl-suva-gray"
          :class="{ 'bg-cl-primary-orange cl-primary-orange' : isActive, 'bg-cl-white cl-white' : !isFilled && !isActive, 'bg-cl-suva-gray cl-suva-gray' : isFilled }"
        >
          1
        </div>
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row">
          <div class="col-xs-12 col-md-5" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <p class="m0 h4 lh30 weight-700 uppercase">
              {{ $t('Personal Details') }}
            </p>
          </div>
          <div class="col-xs-12 col-md-7 pr30">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary h5 flex" @click.prevent="edit">
                <span class="pr5">{{ $t('Edit personal details') }}</span>
                <img :src="'/assets/svg/create.svg'" class="filter-gray" width="26px" height="26px" alt="edit">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-sm-20 m-sm-0" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-10 p-0">
        <div class="row mb-xs-100 p-0-12-xs m-sm-0 between-sm">
          <div class="col-xs-12 px25 visible-xs" :class="{ 'p-0' : currentUser }">
            <div class="pils-buttons" v-if="!currentUser">
              <div
                class="pil"
                @click="variableTabs(0)"
                :class="{ 'active': isActivePill[0].show === true }"
              >
                {{ $t('Personal Details') }}
              </div>
              <div
                class="pil"
                @click="variableTabs(1)"
                :class="{ 'active': isActivePill[1].show === true }"
              >
                {{ $t('Log in') }}
              </div>
            </div>
            <h4 class="mobile-label-header" v-if="currentUser">
              {{ $t('Twoje dane') }}
            </h4>
          </div>

          <!-- <span
            class="link pointer"
            @click.prevent="gotoAccount"
          >
            {{ $t('login to your account') }}
          </span>-->
          <template v-if="isActivePill[0].show || currentUser">
            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              :autofocus="true"
              name="first-name"
              :placeholder="$t('First name *')"
              v-model.trim="personalDetails.firstName"
              @blur="$v.personalDetails.firstName.$touch()"
              autocomplete="given-name"
              :validations="[
                {
                  condition: $v.personalDetails.firstName.$error && !$v.personalDetails.firstName.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.firstName.minLength,
                  text: $t('Name must have at least 3 letters.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              name="last-name"
              :placeholder="$t('Last name *')"
              v-model.trim="personalDetails.lastName"
              @blur="$v.personalDetails.lastName.$touch()"
              autocomplete="family-name"
              :validations="[{
                condition: $v.personalDetails.lastName.$error && !$v.personalDetails.lastName.required,
                text: $t('Field is required')
              }]"
            />

            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              name="street-address"
              :placeholder="$t('Street name *')"
              v-model.trim="personalDetails.streetAddress"
              @blur="$v.personalDetails.streetAddress.$touch()"
              autocomplete="address-line1"
              :validations="[
                {
                  condition: $v.personalDetails.streetAddress.$error && !$v.personalDetails.streetAddress.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.streetAddress.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              name="apartment-number"
              :placeholder="$t('House/Apartment number *')"
              v-model.trim="personalDetails.apartmentNumber"
              @blur="$v.personalDetails.apartmentNumber.$touch()"
              autocomplete="address-line2"
              :validations="[
                {
                  condition: $v.personalDetails.apartmentNumber.$error && !$v.personalDetails.apartmentNumber.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.apartmentNumber.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              name="city"
              :placeholder="$t('City *')"
              v-model.trim="personalDetails.city"
              @blur="$v.personalDetails.city.$touch()"
              autocomplete="address-level2"
              :validations="[
                {
                  condition: $v.personalDetails.city.$error && !$v.personalDetails.city.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.city.maxLength,
                  text: $t('Value is to long.')
                },
                {
                  condition: !$v.personalDetails.city.minLength,
                  text: $t('Value is to short.')
                }
              ]"
            />

            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              name="zip-code"
              :placeholder="$t('Zip-code *')"
              v-model.trim="personalDetails.zipCode"
              @blur="$v.personalDetails.zipCode.$touch()"
              autocomplete="postal-code"
              :validations="[
                {
                  condition: $v.personalDetails.zipCode.$error && !$v.personalDetails.zipCode.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.zipCode.minLength,
                  text: $t('Value is to short.')
                },
                {
                  condition: !$v.personalDetails.zipCode.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />
            <base-select
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              name="countries"
              :options="temporaryCountryOptions"
              :selected="personalDetails.country"
              :placeholder="$t('Country *')"
              :validations="[
                {
                  condition: $v.personalDetails.country.$error && !$v.personalDetails.country.required,
                  text: $t('Field is required')
                }
              ]"
              v-model="personalDetails.country"
              autocomplete="country-name"
              @blur="$v.personalDetails.country.$touch()"
              @change="$v.personalDetails.country.$touch(); changeCountry();"
            />

            <base-input
              class="col-xs-6 col-md-6 mb25 fs-sm-14"
              type="text"
              name="phone-number"
              :placeholder="$t('Phone Number *')"
              @blur="$v.personalDetails.phoneNumber.$touch()"
              v-model.trim="personalDetails.phoneNumber"
              autocomplete="tel"
              :validations="[
                {
                  condition: $v.personalDetails.phoneNumber.$error && !$v.personalDetails.phoneNumber.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.phoneNumber.maxLength,
                  text: $t('Value is to long.')
                }
              ]"
            />

            <base-input
              class="col-xs-12 mb25 fs-sm-14"
              type="email"
              name="email-address"
              :placeholder="$t('Email address *')"
              v-model="personalDetails.emailAddress"
              @blur="$v.personalDetails.emailAddress.$touch()"
              autocomplete="email"
              @keyup.enter="validateData(personalDetails.emailAddress)"
              :validations="[
                {
                  condition: $v.personalDetails.emailAddress.$error && !$v.personalDetails.emailAddress.required,
                  text: $t('Field is required')
                },
                {
                  condition: !$v.personalDetails.emailAddress.email && $v.personalDetails.emailAddress.$error,
                  text: $t('Please provide valid e-mail address.')
                },
                {
                  condition: !$v.personalDetails.emailAddress.maxLength,
                  text: $t('Value is to long.')
                },
                {
                  condition: !$v.personalDetails.emailAddress.emailIsValid,
                  text: $t('Please provide valid e-mail address.')
                }
              ]"
            />

            <base-checkbox
              v-if="!currentUser"
              class="col-xs-12 mb15 fs-sm-14"
              id="createAccountCheckbox"
              @click="createAccount = !createAccount"
              v-model="createAccount"
            >
              {{ $t('I want to create an account') }}
            </base-checkbox>

            <template v-if="createAccount && !currentUser">
              <base-input
                class="col-xs-12 mb25 mt10 fs-sm-14"
                type="password"
                name="password"
                ref="password"
                :placeholder="$t('Password *')"
                v-model="password"
                @blur="$v.password.$touch()"
                autocomplete="new-password"
                :validations="[
                  {
                    condition: $v.password.$error && !$v.password.required,
                    text: $t('Field is required.')
                  },
                  {
                    condition:!$v.password.mustBeCool,
                    text: $t('Minimalna różność klas w haśle to 3. Klasy postaci: małe znaki, wielkie znaki, cyfry, znaki specjalne.')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 mb25 fs-sm-14"
                type="password"
                name="password-confirm"
                :placeholder="$t('Repeat password *')"
                v-model="rPassword"
                autocomplete="new-password"
                :validations="[
                  {
                    condition: $v.rPassword.$error && !$v.rPassword.required,
                    text: $t('Field is required.')
                  },
                  {
                    condition:!$v.rPassword.sameAsPassword,
                    text: $t('Passwords must be identical.')
                  },
                  {
                    condition:!$v.rPassword.mustBeCool,
                    text: $t('Minimalna różność klas w haśle to 3. Klasy postaci: małe znaki, wielkie znaki, cyfry, znaki specjalne.')
                  }
                ]"
              />

              <base-checkbox
                class="col-xs-12 mb15 fs-sm-14"
                id="acceptConditions"
                @click="acceptConditions = !acceptConditions"
                @blur="$v.acceptConditions.$touch()"
                v-model="acceptConditions"
                :validations="[{
                  condition: !$v.acceptConditions.required && $v.acceptConditions.$error,
                  text: $t('You must accept the terms and conditions.')
                }]"
              >
                {{ $t('I accept ') }}
                <span
                  class="link pointer"
                  @click.prevent="$bus.$emit('modal-toggle', 'modal-terms')"
                >{{ $t('Terms and conditions') }}</span>*
              </base-checkbox>
            </template>
          </template>
          <template v-if="isActivePill[1].show && !currentUser">
            <login />
          </template>
        </div>
      </div>
    </div>
    <div class="row mobile-footer-nav" v-show="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-10">
        <div class="row my30 m-xs-0">
          <div class="col-xs-12 col-md-7 px20 button-container" @click="changeFilled(true)">
            <button-full
              data-testid="personalDetailsSubmit"
              @click.native="validateData(personalDetails.emailAddress)"
              :disabled="createAccount ? $v.$invalid : $v.personalDetails.$invalid"
              class="mobile-footer-nav-but"
              :class="{ 'disable-but' : !isFilled && !isActive }"
            >
              {{ $t('Continue to shipping') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'
import { PersonalDetails } from '@vue-storefront/core/modules/checkout/components/PersonalDetails'

import { mapState } from 'vuex'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import Login from 'theme/components/core/blocks/Auth/Login'
import Vue from 'vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)
const mustBeCool = (value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])^.{6,}$/g) !== null
const emailIsValid = (email) => email.match(/\+/) === null

export default {
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    Login
  },
  data () {
    return {
      temporaryCountryOptions: [{ 'value': 'PL', 'label': 'Poland' }],
      isActivePill: [{ show: true }, { show: false }],
      validationCalls: 0,
      re: new RegExp('^([a-z0-9]{5,})$')
    }
  },
  mounted () {
    this.changeFilled()
  },
  methods: {
    onLoggedIn (receivedData) {
      const userBasic = {
        firstName: receivedData.firstname,
        lastName: receivedData.lastname,
        emailAddress: receivedData.email
      }
      let userWithAdress = receivedData.addresses[0] ? {
        streetAddress: receivedData.addresses[0].street[0],
        apartmentNumber: receivedData.addresses[0].street[1],
        city: receivedData.addresses[0].city,
        zipCode: receivedData.addresses[0].postcode,
        country: receivedData.addresses[0].country_id,
        phoneNumber: receivedData.addresses[0].telephone
      } : {
        streetAddress: null,
        apartmentNumber: null,
        city: null,
        zipCode: null,
        country: null,
        phoneNumber: null
      }
      this.personalDetails = {
        ...userBasic, ...userWithAdress
      }
    },
    variableTabs (z) {
      for (var i = 0; i < 2; i++) {
        this.isActivePill[i].show = false
      }
      this.isActivePill[z].show = true
    },
    changeFilled (filed) {
      if (filed) {
        this.$emit('personalDetailsUpdate', true)
      } else {
        this.$emit('personalDetailsUpdate', this.isFilled)
      }
    },
    validateData (email) {
      this.validateMailbox(email).then((res) => {
        if (!res) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Zmieniłeś adres email zbyt wiele razy. Spróbuj ponownie za 5 minut.'),
            action1: { label: this.$t('OK') }
          })
          if (this.validationCalls === 4) {
            setTimeout(() => {
              this.validationCalls = 0
            }, 300000)
          }
          return false
        } else if (!res.valid) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Podany adres email nie istnieje!'),
            action1: { label: this.$t('OK') }
          })
          return false
        } else if (res.is_disposable) {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Nie możesz zapisać się do newslettera korzystając z tymczasowego adresu email!'),
            action1: { label: this.$t('OK') }
          })
          return false
        } else if (res.mailbox_verification === 'false') {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Podany adres email jest nieprawidłowy!'),
            action1: { label: this.$t('OK') }
          })
          return false
        } else {
          this.sendDataToCheckout()
          return true
        }
      })
    },
    validateMailbox (value) {
      this.validationCalls++
      if (this.validationCalls >= 4) {
        return Promise.resolve(false)
      }
      return this.$http.get('https://api.mailgun.net/v3/address/validate', { params: { address: value, api_key: 'pubkey-7df01f9dee468071ab27e08573a02adc', mailbox_verification: 'true' } }).then(response => {
        return {
          valid: response.data.is_valid,
          is_disposable: response.data.is_disposable_address,
          mailbox_verification: response.data.mailbox_verification
        }
      })
    }
  },
  mixins: [PersonalDetails],
  validations: {
    personalDetails: {
      firstName: {
        required,
        minLength: minLength(3),
        unicodeAlpha
      },
      lastName: {
        required,
        unicodeAlpha
      },
      emailAddress: {
        required,
        email,
        maxLength: maxLength(200),
        emailIsValid
      },
      streetAddress: {
        required,
        maxLength: maxLength(60),
        unicodeAlphaNum
      },
      apartmentNumber: {
        required,
        maxLength: maxLength(10)
      },
      city: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(3),
        unicodeAlpha
      },
      zipCode: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
        unicodeAlphaNum
      },
      country: {
        required
      },
      phoneNumber: {
        required,
        maxLength: maxLength(50)
      }
    },
    password: {
      required,
      mustBeCool
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password'),
      mustBeCool
    },
    acceptConditions: {
      required
    }
  },
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    ...mapState({
      currentUser: state => state.user.current
    })
  }
}
</script>

<style lang="scss" scoped>
.filter-gray {
  filter: contrast(0.3);
}
// .number-circle {
//   background-color: #E74141;
//   color: #E74141;
//   border: 1px solid #CFCED8;
// }
 @media (min-width: 768px) {
   .mb25 {
      margin-bottom: 0px;
   }
 }
.mobile-footer-nav-but {
  font-weight: 500;
}
.step-title {
  @media (min-width: 768px) {
    display: flex;
  }
  align-items: center;
  // margin-bottom: 15px;
  padding-left: 27px;
}
.pl-sm-20 {
  padding-left: 20px;
}
.link {
  text-decoration: underline;
}

.login-prompt {
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .between-sm {
    justify-content: space-between;
  }
  .col-xs-6 {
    flex-basis: 48%;
    max-width: 48%;
  }
  .m-sm-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .pl-sm-20 {
    padding-left: 0px;
  }
  .mobile-footer-nav {
    margin: 0;
    position: fixed;
    background: rgb(255, 255, 255);
    width: 100%;
    padding: 20px 30px;
    height: 80px;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid rgb(206, 206, 206);
    z-index: 999;
    box-sizing: border-box;
  }
  .mobile-footer-nav-but {
    height: 40px;
    padding: 0;
    font-size: 16px;
  }
  .disable-but {
    background-color: #d9d9d9;
  }
  .m-xs-0 {
    margin: 0;
  }
  .pils-buttons {
    display: flex;
    align-items: center;
    text-align: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    background-color: white;
    border: 1px solid #191919;
    margin: 0px 0px 25px 0px;
  }
  .pils-buttons .pil {
    width: 50%;
    padding: 5px 0;
    color: #191919;
    font-size: 14px;
  }
  .pils-buttons .pil.active {
    width: 50%;
    padding: 8px 0;
    color: white;
    font-size: 14px;
    background-color: #191919;
  }
  .fs-sm-14 {
    font-size: 14px;
  }
  .mb-xs-100 {
    margin-bottom: 100px;
  }
  .mobile-label-header {
    width: 85%;
    margin: 0px auto 28px;
    padding: 8px 0;
    color: white;
    font-size: 14px;
    background-color: #E74141;
    text-align: center;
  }
  .p-0 {
    padding: 0;
  }
  .p-0-12-xs {
    padding: 0 12px;
  }
}
.pils-buttons {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  background-color: white;
  border: 1px solid #191919;
  margin: 0px 0px 25px 0px;
}
.pils-buttons .pil {
  width: 50%;
  padding: 5px 0;
  color: #191919;
  font-size: 14px;
}
.pils-buttons .pil.active {
  width: 50%;
  padding: 8px 0;
  color: white;
  font-size: 14px;
  background-color: #041D59;
}
</style>

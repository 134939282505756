<template>
  <div id="checkout">
    <div class="container">
      <div class="row between-sm flex mt60" v-show="!isThankYouPage" v-if="!isMobile">
        <div class="col-sm-6 col-xs-12 pb70">
          <div class="checkout-title py5 px20">
            <div class="row">
              <div class="col-sm-2 col-md-1" />
              <div class="col-sm-9 col-md-10 h3 title-font pb30 uppercase">
                {{ $t('Realizacja zamówienia') }}
              </div>
            </div>
          </div>
          <div v-if="OfflineOnly">
            <personal-details
              class="line relative"
              :is-active="activeSection.personalDetails"
              :focused-field="focusedField"
              @personalDetailsUpdate="onPersonalDetailsUpdate"
              :online="false"
            />
            <shipping
              class="line relative"
              :is-active="activeSection.shipping"
              @shippingUpdate="onShippingUpdate"
            />
          </div>
          <div v-if="OnlineOnly" class="w-100">
            <personal-details-locit
              class="line relative"
              :is-active="activeSection.personalDetails"
              :focused-field="focusedField"
              @personalDetailsUpdate="onPersonalDetailsUpdate"
              :online="true"
            />
            <shipping-locit
              class="line relative"
              :is-active="activeSection.shipping"
              @shippingUpdate="onShippingUpdate"
            />
          </div>
          <payment class="line relative" :is-active="activeSection.payment" />
          <order-review
            class="relative"
            :is-active="activeSection.orderReview"
            :class="{ 'line' : activeSection.orderReview }"
          />
          <p class="mb0 align-right w-100">
            * pole obowiązkowe
          </p>
          <div id="custom-steps" />
        </div>
        <div class="col-sm-5" v-if="!isMobile">
          <cart-summary />
        </div>
      </div>
      <ul class="order_steps" v-if="isMobile" v-show="!isThankYouPage">
        <li class="order_step">
          <div
            class="number-circle lh35 cl-white bg-cl-white brdr-circle align-center weight-700 brdr-color-matterhorn"
            :class="{ 'number-circle-active' : activeSection.personalDetails, 'bg-cl-white' : !activeSection.personalDetails, 'bg-cl-silver' : Object.keys(validationResults.personalDetails).length > 1 }"
            @click.prevent="editPersonalDetails"
          />
          <p class="m0 mb5 cl-accent">
            {{ $t('Personal Details') }}
          </p>
        </li>
        <li class="order_step">
          <div
            class="number-circle lh35 cl-white brdr-circle align-center weight-700"
            :class="{ 'number-circle-active' : activeSection.shipping, 'bg-cl-white' : !activeSection.shipping, 'bg-cl-silver' : !validationResults.shipping.$invalid }"
            @click.prevent="editShipping"
          />
          <p class="m0 mb5 cl-accent">
            {{ $t('Shipping') }}
          </p>
        </li>
        <li class="order_step">
          <div
            class="number-circle lh35 cl-white brdr-circle align-center weight-700"
            :class="{ 'number-circle-active' : activeSection.payment, 'bg-cl-white' : !activeSection.payment, 'bg-cl-silver' : !validationResults.payment.$invalid }"
            @click.prevent="editPayment"
          />
          <p class="m0 mb5 cl-accent">
            {{ $t('Payment') }}
          </p>
        </li>
        <li class="order_step">
          <div
            class="number-circle lh35 cl-white brdr-circle align-center weight-700"
            :class="{ 'number-circle-active' : activeSection.orderReview, 'bg-cl-white' : !activeSection.orderReview }"
          />
          <p class="m0 mb5 cl-accent">
            {{ $t('Review order') }}
          </p>
        </li>
      </ul>
      <div class="row m-sm-0" v-if="isMobile" v-show="!isThankYouPage">
        <div v-if="OfflineOnly">
          <personal-details
            class="line relative"
            :is-active="activeSection.personalDetails"
            :focused-field="focusedField"
            @personalDetailsUpdate="onPersonalDetailsUpdate"
            :online="false"
          />
          <shipping
            class="line relative"
            :is-active="activeSection.shipping"
            @shippingUpdate="onShippingUpdate"
          />
        </div>
        <div v-if="OnlineOnly" class="w-100">
          <personal-details-locit
            class="line relative"
            :is-active="activeSection.personalDetails"
            :focused-field="focusedField"
            @personalDetailsUpdate="onPersonalDetailsUpdate"
            :online="true"
          />
          <shipping-locit
            class="line relative"
            :is-active="activeSection.shipping"
            @shippingUpdate="onShippingUpdate"
          />
        </div>
        <payment
          class="line relative"
          :is-active="activeSection.payment"
          @paymentUpdate="onPaymentUpdate"
        />
        <order-review class="line relative" :is-active="activeSection.orderReview" />
        <div id="custom-steps">
          <!-- sd -->
        </div>
        <div class="col-sm-5 bg-cl-secondary" v-if="!isMobile">
          <cart-summary />
        </div>
      </div>
    </div>
    <thank-you-page v-show="isThankYouPage" />
  </div>
</template>

<script>
import { MobileDetected } from '../components/core/mobileDetected.ts'
import Checkout from '@vue-storefront/core/pages/Checkout'
import PersonalDetailsLocit from 'theme/components/core/blocks/Checkout/PersonalDetailsLocit';
import PersonalDetails from 'theme/components/core/blocks/Checkout/PersonalDetails'
import ShippingLocit from 'theme/components/core/blocks/Checkout/ShippingLocit';
import Shipping from 'theme/components/core/blocks/Checkout/Shipping'
import Payment from 'theme/components/core/blocks/Checkout/Payment'
import OrderReview from 'theme/components/core/blocks/Checkout/OrderReview'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import ThankYouPage from 'theme/components/core/blocks/Checkout/ThankYouPage'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { PaymentBackendMethodsModule } from 'src/modules/payment-backend-methods'
import { PaymentCashOnDeliveryModule } from 'src/modules/payment-cash-on-delivery'
import { PaymentPayUModule } from 'src/modules/vsf-payment-payu'
import { PaymentPayUBlikModule } from 'src/modules/vsf-payment-payu-blik'
import { PaymentPayInStoreModule } from 'src/modules/payment-pay-in-store'
import { GoogleRecaptcha } from 'src/modules/vsf-recaptcha'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      isPersonalFilled: '',
      IsShippingFilled: '',
      IsPaymentFilled: '',
      isMobile: global.innerWidth < 768
    }
  },
  components: {
    PersonalDetails,
    PersonalDetailsLocit,
    Shipping,
    ShippingLocit,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage
  },
  mixins: [Checkout, MobileDetected],
  beforeCreate () {
    registerModule(PaymentBackendMethodsModule)
    registerModule(PaymentCashOnDeliveryModule)
    registerModule(PaymentPayInStoreModule)
    registerModule(PaymentPayUModule)
    registerModule(PaymentPayUBlikModule)
    registerModule(OrderModule)
    registerModule(GoogleRecaptcha)
  },
  mounted () {
    this.showRecaptchaBadge()
  },
  beforeDestroy () {
    this.hideRecaptchaBadge()
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  methods: {
    showRecaptchaBadge () {
      if (document.getElementsByClassName('grecaptcha-badge').length) document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    hideRecaptchaBadge () {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
    },
    onPersonalDetailsUpdate (isPersonalFilled) {
      this.isPersonalFilled = isPersonalFilled
    },
    onShippingUpdate (IsShippingFilled) {
      this.IsShippingFilled = IsShippingFilled
    },
    onPaymentUpdate (IsPaymentFilled) {
      this.IsPaymentFilled = IsPaymentFilled
    },
    editPersonalDetails () {
      this.$bus.$emit('checkout-before-edit', 'personalDetails')
    },
    editShipping () {
      if (this.activeSection.payment || this.activeSection.orderReview) {
        this.$bus.$emit('checkout-before-edit', 'shipping')
      }
    },
    editPayment () {
      if (this.activeSection.orderReview) {
        this.$bus.$emit('checkout-before-edit', 'payment')
      }
    },
    notifyEmptyCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Shopping cart is empty. Please add some products before entering Checkout'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyOutStock (chp) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: chp.name + this.$t(' is out of stock!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNotAvailable () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Some of the ordered products are not available!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyStockCheck () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Stock check in progress, please wait while available stock quantities are checked'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNoConnection () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.'),
        action1: { label: this.$t('OK') }
      })
    }
  },
  metaInfo () {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
  @import '~theme/css/base/text';
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $bg-secondary: color(secondary, $colors-background);
  $color-tertiary: color(tertiary);
  $color-secondary: color(secondary);
  $color-error: color(error);
  $color-white: color(white);
  $color-black: color(black);

  .mt-125 {
    margin-top: 125px;
  }
  .flex {
    display: flex;
  }
  #checkout {
    min-height: calc(100vh - 120px);
    .number-circle {
      width: 25px;
      height: 25px;

      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }
    .radioStyled {
      // display: block;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        // top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid $color-tertiary;

        &:after {
          content: "";
          position: absolute;
          display: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }
      input:checked ~ .checkmark:after {
        display: block;
        background: #041D59;
      }
    }
  }

  .line {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 26px;
      left: 32px;
      z-index: -1;
      width: 1px;
      height: 100%;
      background-color: #CFCED8;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .checkout-title {
    @media (max-width: 767px) {
      background-color: $color-tertiary;
      margin-bottom: 25px;

      h1 {
        font-size: 36px;
      }
    }
  }
  @media (max-width: 767px) {
    .order_steps {
      display: flex;
      text-align: center;
      padding: 0 15px;
      list-style-type: none;
      justify-content: space-between;
      position: relative;
    }
    .order_step {
      width: 25%;
      text-align: center;
    }
    .order_step::after {
      content: "";
      width: 70%;
      height: 1px;
      display: block;
      background: #c6c6c6;
      position: absolute;
      top: 12px;
      left: 55px;
      z-index: -1;
    }
    .number-circle {
    margin: 0 auto;
    width: 23px !important;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    border: 1px solid #c6c6c6;
    height: 23px !important;
    // color: #1166fb;
    // background-color: #ffffff !important;
    }
    .number-circle-active {
    // color: #FFF;
    background-color: #041D59 !important;
    }
    .order_steps p {
     font-size: 11px;
     padding-top: 5px;
    }
    .line.relative {
      width: 100%;
    }
  }
</style>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .m-sm-0 {
      margin-left: 0px;
      margin-right: 0px;
    }
    .container {
      padding: 0;
    }
  }
</style>

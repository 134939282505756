<template>
  <div class="order-review ">
    <div class="row pl20 hidden-xs step-title">
      <div class="col-xs-1 col-sm-2 col-md-1 p0">
        <div
          class="number-circle lh35 brdr-circle align-center weight-700 brdr-1 brdr-cl-tertiary bg-cl-white cl-tertiary"
          :class="{ 'bg-cl-alternative brdr-cl-alternative active' : isActive, 'bg-cl-tertiary cl-tertiary' : !isFilled && !isActive, 'bg-cl-tertiary cl-tertiary' : isFilled && !isActive }"
        />
      </div>
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row">
          <div class="col-md-12">
            <p class="m0 h4 lh30 weight-700 uppercase">
              {{ $t('Order Summary') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="isActive">
      <div class="hidden-xs col-sm-2 col-md-2" />
      <div class="col-xs-12 col-sm-9 col-md-10 p-0">
        <div id="checkout-order-review-additional-container">
          <div id="checkout-order-review-additional" />
        </div>
        <div class="row mb25">
          <div class="col-xs-12 p-0">
            <div class="row m-0">
              <div class="col-xs-12 p-0 visible-xs">
                <p class="mobile-label-header h6">
                  {{ $t('Order Summary') }}
                </p>
              </div>
              <div class="cartsummary-wrapper">
                <cart-summary />
              </div>
              <base-checkbox
                class="col-xs-12 col-sm-12 col-md-12 mt10 p-sm-16 relative acceptTermsCheckbox"
                id="acceptTermsCheckbox"
                @blur="$v.orderReview.terms.$touch()"
                v-model="orderReview.terms"
                :validations="[{
                  condition: !$v.orderReview.terms.required && $v.orderReview.terms.$error,
                  text: $t('Field is required')
                }]"
              >
                {{ $t('Przeczytałem i akceptuję ') }}
                <router-link class="cl-alternative no-underline" target="_blank" :to="localizedRoute('/i/regulamin-serwisu')" exact>
                  {{ $t('Regulamin Sklepu') }}
                </router-link>
                {{ $t(' oraz ') }}
                <router-link class="cl-alternative no-underline" target="_blank" :to="localizedRoute('/i/polityka-prywatnosci')" exact>
                  {{ $t('Politykę Prywatności *') }}
                </router-link>
              </base-checkbox>
              <!-- <base-checkbox
                class="col-xs-12 col-sm-12 col-md-12 p-sm-16 mt10  acceptNewsletterCheckbox"
                id="acceptNewsletterCheckbox"
                v-model="orderReview.newsletter"
              >
                {{ $t('Zgadzam się na otrzymywanie e-mail newslettera') }}
              </base-checkbox> -->
              <!-- <base-checkbox
                class="col-xs-12 col-sm-12 col-md-12 p-sm-16 mt10 acceptSmsNewsletterCheckbox"
                id="acceptSmsNewsletterCheckbox"
                v-model="orderReview.smsnewsletter"
              >
                {{ $t('Zgadzam się na otrzymywanie sms newslettera') }}
                <span
                  class="link pointer"
                  @click.prevent="$bus.$emit('modal-toggle', 'modal-terms')"
                />
              </base-checkbox> -->
              <!-- <base-checkbox
                class="col-xs-12 col-sm-12 col-md-12 p-sm-16 mt10 zaufaneopinieCheckbox"
                id="ceneoConsentCheckbox"
                @click="toggleCeneoConsent()"
                :value="getCeneoConsent"
              >
                {{ $t('Zgadzam się na uczestnictwo w programie Zaufane Opinie') }}
              </base-checkbox>
              <base-checkbox
                class="col-xs-12 col-sm-12 col-md-12 p-sm-16 mt10 zaufaneopinieCheckbox"
                id="zaufaneopinieCheckbox"
                v-model="orderReview.zaufaneopinie"
              >
                {{ $t('Wyrażam zgodę na przekazanie mojego adresu e-mail w celu udziału w programie Zaufane Opinie') }}
                <span
                  class="link pointer"
                  @click.prevent="$bus.$emit('modal-toggle', 'modal-terms')"
                />
              </base-checkbox> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mobile-footer-nav" v-show="isActive">
      <div class="hidden-xs col-sm-2 col-md-2" />
      <div class="col-xs-12 col-sm-9 col-md-10 save-bottom">
        <div class="row">
          <div class="col-xs-12 col-md-12 m-xs-0 center-flex-text">
            <slot name="placeOrderButton">
              <button-full
                @click.native="placeOrder"
                data-testid="orderReviewSubmit"
                class="place-order-btn mobile-footer-nav-but w-100"
                :disabled="$v.orderReview.$invalid || (!blikValidation && paymentDetails.paymentMethod === 'vue_payu_gateway_blik')"
                :class="{ 'disable-but' : !isFilled && !isActive }"
              >
                {{ $t('Place the order') }}
              </button-full>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <modal name="modal-terms" static-data="terms">
      <p slot="header">
        {{ $t('Terms and conditions') }}
      </p>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Composite from '@vue-storefront/core/mixins/composite'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import ButtonFull from 'theme/components/theme/ButtonFull'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import Modal from 'theme/components/core/Modal'
import { OrderReview } from '@vue-storefront/core/modules/checkout/components/OrderReview'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { storeOrderSummary } from 'theme/helpers/storeOrderSummary'
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      blikValidation: false,
      showMoreText: { first: false, second: false, third: false }
    }
  },
  components: {
    BaseCheckbox,
    ButtonFull,
    CartSummary,
    Modal
  },
  mixins: [OrderReview, Composite],
  validations: {
    orderReview: {
      terms: {
        required
      }
    }
  },
  beforeCreate () {
    registerModule(OrderModule)
  },
  beforeMount () {
    this.$bus.$on('changeUpdate', this.blikValidationCall)
  },
  beforeDestroy () {
    this.$bus.$off('changeUpdate', this.blikValidationCall)
  },
  computed: {
    ...mapState({
      paymentDetails: state => state.checkout.paymentDetails
    }),
    ...mapGetters({
      getShippingDetails: 'checkout/getShippingDetails',
      getPaymentDetails: 'checkout/getPaymentDetails',
      getPersonalDetails: 'checkout/getPersonalDetails',
      getCeneoConsent: 'ui/getCeneoConsent'
    })
  },
  methods: {
    storeOrderSummary: storeOrderSummary,
    blikValidationCall (v) {
      this.blikValidation = v
    },
    showMore (z) {
      if (z === 0) {
        this.showMoreText.first = true
      }
    },
    toggleCeneoConsent () {
      this.$store.dispatch('ui/toogleCeneoConsent')
    },
    async placeOrder () {
      try {
        this.storeOrderSummary()
      } catch (err) {
        if (
          err.name === 'QuotaExceededError' ||
          err.name === 'NS_ERROR_DOM_QUOTA_REACHED'
        ) {
          localStorage.clear()
          try {
            this.storeOrderSummary()
          } catch {
            console.log('store is full')
          }
        }
      }
      if (this.getPersonalDetails.createAccount) {
        await this.$store.dispatch('googleRecaptcha/isVerifiedCaptcha')
        if (this.$store.state.googleRecaptcha.google_generated_token) {
          this.register()
        }
      } else {
        this.$bus.$emit('checkout-before-placeOrder')
      }
      if (this.orderReview.newsletter) {
        this.$store.dispatch('edrone/subscribe', {
          email: this.$store.state.checkout.personalDetails.emailAddress,
          tag: 'From checkout'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-footer-nav-but {
    width: 100%;
  } q
  .link {
    text-decoration: underline;
  }
  .step-title {
    @media (min-width: 768px) {
      display: flex;
    }
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-left: 27px;
  }
  .cartsummary-wrapper {
    @media (min-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .mobile-footer-nav {
      position: fixed;
      background: rgb(255, 255, 255);
      width: 100%;
      padding: 10px 30px;
      left: 0px;
      bottom: 0px;
      border-top: 1px solid rgb(206, 206, 206);
      z-index: 999;
      box-sizing: border-box;
    }
    .disable-but {
      background-color: #d9d9d9;
    }
    .m-xs-0 {
      margin: 0;
    }
    .mobile-label-header {
        margin-top:0;
        padding: 7px 0px 7px 16px;
        background-color: #041D59;
        color: #ffffff;
        text-transform: uppercase;
    }
    .p-0 {
     padding: 0;
    }
    .p-sm-16 {
      padding: 0 16px;
    }
    .cartsummary-wrapper {
      width:100%;
    }
    .more-text {
      display: contents;
    }
    .show-more {
      color: #041D59;
    }
  }
</style>

import { render, staticRenderFns } from "./ShippingLocit.vue?vue&type=template&id=5b5165ae&scoped=true&"
import script from "./ShippingLocit.vue?vue&type=script&lang=js&"
export * from "./ShippingLocit.vue?vue&type=script&lang=js&"
import style0 from "./ShippingLocit.vue?vue&type=style&index=0&id=5b5165ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5165ae",
  null
  
)

export default component.exports